import * as React from "react";
import styled from "styled-components";
import NextButton from "../../../shared/components/NextButton";
import Cheese from "../../../assets/images/Cheese.svg";
import { Image } from "semantic-ui-react";
import PageLayout from "../../../shared/components/PageTemplate";
import { StyledContainer } from "../../../shared/components/StyledContainer";
import { device } from "../../../shared/helpers/deviceConfig";

const Text = styled.div`
  margin-left: 27px;
  margin-top: 50px;
  text-align: left;
  > li {
    text-align: left;
    margin-left: 30px;
  }

  @media ${device.desktop} {
    margin-left: 300px;
  }
  @media ${device.desktopL} {
    margin-left: 243px;
  }
`;

const CheeseImage = styled(Image)`
  width: 109px;
  height: 116px;
  left: 96px;
  @media ${device.tablet} {
    left: 198px;
  }
  @media ${device.laptopL} {
    left: 198px;
  }
  @media ${device.desktop} {
    left: 198px;
  }
  @media ${device.desktopL} {
    left: 406px;
  }
`;

interface INowWhat2 {
  onClose: () => void;
  onExitGame: () => void;
  setTutorial: (setTutorial: boolean) => void;
  isTutorial: boolean;
}

const NowWhat2: React.FC<INowWhat2> = ({ onClose, isTutorial, onExitGame, setTutorial }) => {
  return (
    <PageLayout
      title="Tutorial End"
      icon="close"
      confirmExit={onClose}
      isTutorial={isTutorial}
      includeFooter={false}
      buttonIcon={false}
    >
      <StyledContainer>
        <CheeseImage src={Cheese} alt="piece of yellow swiss cheese with holes" />
        <Text>
          <p data-testid="what-now-text">So now that you’ve:</p>
          <li>Settled into your CIO role </li>
          <li>Spent some Budget</li>
          <li>Launched an Initiative Strategy </li>
          <li>Responded to an Event</li>
          <li>Seen its effects on your Metrics</li>
          <br />
          <p style={{ marginBottom: "60px" }}>Would you like to...</p>
        </Text>
        <NextButton
          testId="gameStart"
          disabled={false}
          onClick={() => {
            onExitGame();
            setTutorial(false);
          }}
          text="Play game"
          buttonIcon={true}
        />
        <br />
        <NextButton
          testId="tutorialStart"
          disabled={false}
          onClick={() => {
            onExitGame();
            setTutorial(true);
          }}
          text="Start tutorial again?"
          buttonIcon={true}
        />
      </StyledContainer>
    </PageLayout>
  );
};
export default NowWhat2;
