import { IInitiativeSelection } from "../pages";

export const getFundLevelMessage = (selectedInitiatives: IInitiativeSelection[], fundLimit: number) => {
  const maxChoicesRemaining = fundLimit - selectedInitiatives.length;
  const singularOrPluralInitiatives = maxChoicesRemaining > 1 ? "initiatives" : "initiative";
  const hasNotChosen = selectedInitiatives.length === 0;
  const noChoicesRemaining = selectedInitiatives.length >= fundLimit;

  if (noChoicesRemaining) return "You have invested your maximum initiatives for this year";
  if (hasNotChosen) {
    return `Choose up to ${fundLimit} ${singularOrPluralInitiatives} to fund per year`;
  } else {
    return `You can fund ${maxChoicesRemaining} more ${singularOrPluralInitiatives}`;
  }
};
