import * as React from "react";
import EnterName from "../components/EnterName";
import TutorialSelect from "../components/TutorialSelect";
import { useState } from "react";
import { GameConfig, Company } from "../../app";
import FirePlayer from "./firePlayer";
import { IMetrics } from "../../interfaces";
import { IInitiativeSelection } from "../../initiatives/pages";
import { IEventsSelection } from "../../events/domain_model/eventList";

interface Props {
  state: GameConfig;
  updateGameState: (gameState: GameConfig) => void;
  onSetupCompleted: () => void;
  playerName: string;
  companyName: Company;
  metrics: IMetrics;
  initiativeState: IInitiativeSelection[];
  eventState: IEventsSelection[];
}

const GameSetup: React.FC<Props> = ({
  state,
  updateGameState,
  onSetupCompleted,
  playerName,
  companyName,
  initiativeState,
  eventState,
  metrics,
}) => {
  const [page, setPage] = useState(state.initialPage || "enterName");

  switch (page) {
    case "enterName":
    default:
      return (
        <EnterName
          playerName={state.name}
          setPlayerName={name => updateGameState({ ...state, name })}
          onDone={() => setPage("tutorialSelect")}
        />
      );
    case "tutorialSelect":
      return (
        <TutorialSelect
          playerName={state.name}
          setTutorial={tutorial => updateGameState({ ...state, tutorial })}
          onDone={onSetupCompleted}
        />
      );
    case "firePlayer":
      return (
        <FirePlayer
          setTutorial={tutorial => updateGameState({ ...state, tutorial })}
          onDone={onSetupCompleted}
          playerName={playerName}
          companyName={companyName}
          metrics={metrics}
          initiativesState={initiativeState}
          eventState={eventState}
        />
      );
  }
};

export default GameSetup;
