import { IMetrics } from "../../interfaces/metrics";
import { getStartingMetrics } from "../../company/domain_model";
import { Company } from "../../app";
import { IYearReport } from "../../interfaces/calendarReport";
import arrowUp from "../../assets/images/score/Trending-up.svg";
import arrowDown from "../../assets/images/score/Trending-down.svg";

export const getMetricImage = (metric: keyof IMetrics, companyName: Company, report: IYearReport, route: string) => {
  const startingMetrics = getStartingMetrics(companyName);
  let image: string = "";
  let currentMetrics: number = 0;

  let previousMetrics: number = 0;
  switch (report.currentQuarter) {
    case 0:
      if (report.currentYear === 1) {
        image = "";
      }
      break;
    case 1:
      if (report.currentYear === 1) {
        currentMetrics =
          report.calendarYearsInfo[report.currentYear - 1].quarters[report.currentQuarter - 1].metrics[
            metric as keyof IMetrics
          ];
        previousMetrics = startingMetrics[metric as keyof IMetrics];
      }
      if (report.currentYear === 2 || report.currentYear === 3) {
        currentMetrics =
          report.calendarYearsInfo[report.currentYear - 1].quarters[report.currentQuarter - 1].metrics[
            metric as keyof IMetrics
          ];
        previousMetrics =
          report.calendarYearsInfo[report.currentYear - 2].quarters[3].metrics[metric as keyof IMetrics];
      }
      break;
    default:
      currentMetrics =
        report.calendarYearsInfo[report.currentYear - 1].quarters[report.currentQuarter - 1].metrics[
          metric as keyof IMetrics
        ];
      previousMetrics =
        report.calendarYearsInfo[report.currentYear - 1].quarters[report.currentQuarter - 2].metrics[
          metric as keyof IMetrics
        ];
      break;
  }
  switch (route) {
    case "score":
      currentMetrics = report.calendarYearsInfo[report.currentYear - 1].quarters[3].metrics[metric as keyof IMetrics];
      previousMetrics = report.calendarYearsInfo[report.currentYear - 1].quarters[2].metrics[metric as keyof IMetrics];
  }

  if (previousMetrics !== 0 && currentMetrics > previousMetrics) {
    return (image = arrowUp);
  } else if (currentMetrics < previousMetrics) {
    return (image = arrowDown);
  } else {
    return image;
  }
};
