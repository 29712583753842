import React from "react";
import { Grid, Image, Progress } from "semantic-ui-react";
import Productivity from "../../assets/images/metrics/Productivity.svg";
import Satisfaction from "../../assets/images/metrics/Satisfaction.svg";
import People from "../../assets/images/metrics/People.svg";
import Stability from "../../assets/images/metrics/Stability.svg";
import styled from "styled-components";
import { IMetrics } from "../../interfaces/metrics";

const IconImage = styled(Image)`
  width: 27px;
  height: 20px;
  left: 51px;
`;

const MetricsTitle = styled.div`
   {
    font-size: 18px;
    line-height: 26px;
  }
`;
const MetricsText = styled.div`
   {
    font-size: 14px;
    line-height: 24px;
  }
`;
const ProgressBarStyle = styled.div`
   {
    padding: 18px;
  }
`;
interface Props {
  metrics: IMetrics;
  displayHelperText: boolean;
}

export function formatObjectKey(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/([A-Z])/g, " $1");
}

export function findImage(key: string) {
  switch (formatObjectKey(key)) {
    case "People":
      return People;
    case "Customer Satisfaction":
      return Satisfaction;
    case "Productivity":
      return Productivity;
    case "Stability":
      return Stability;
  }
}

const setAltText = (key: string) => {
  switch (formatObjectKey(key)) {
    case "People":
      return "Outline of a male and female icon";
    case "Customer Satisfaction":
      return "Round smiling face outline";
    case "Productivity":
      return "Chart with X and Y axis showing upwards trend";
    case "Stability":
      return "Outline of a laptop with angle brackets";
  }
};

export function getMetricText(key: string) {
  switch (formatObjectKey(key)) {
    case "People":
      return "Your team’s health and capability";
    case "Customer Satisfaction":
      return "How happy are your customers?";
    case "Productivity":
      return "Your organisational performance";
    case "Stability":
      return "Availability/reliability of your service";
  }
}

const Metrics: React.FC<Props> = ({ metrics, displayHelperText }) => {
  const options = [];
  for (let [key, value] of Object.entries(metrics)) {
    options.push(key, value);
  }
  return (
    <>
      {Object.entries(metrics).map(option => {
        return (
          <Grid columns={2} key={`${option[0]}-metric`}>
            <Grid.Row data-testid={`${option[0]}-metric`}>
              <Grid.Column className="left">
                <IconImage src={findImage(option[0])} alt={setAltText(option[0])} data-testid="alt-text-icon-metric" />
                <ProgressBarStyle>
                  <Progress
                    percent={option[1]}
                    size={"tiny"}
                    alt="White rectangle, partially filled in to indicate level of progress out of 100"
                    indicating
                    data-testid={`${option[0]}-bar`}
                  />
                </ProgressBarStyle>
              </Grid.Column>
              <Grid.Column className="right">
                <MetricsTitle data-testid={`${option[0]}-metric-title`}>
                  <strong>{formatObjectKey(option[0])}</strong>
                </MetricsTitle>
                {displayHelperText && <MetricsText>{getMetricText(option[0])}</MetricsText>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      })}
    </>
  );
};

export default Metrics;
