import * as React from "react";
import { useParams } from "react-router-dom";
import HelmetTags from "../components/Helmet";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import NextButton from "../../shared/components/NextButton";

import { device } from "../../shared/helpers/deviceConfig";

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Footer = styled.div`
  max-width: 100%;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
`;

const ImageContainer = styled.div`
  display: flex;

  @media ${device.mobileS} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }

  @media ${device.mobileM} {
    max-width: 400px;
    margin-left: calc(50% - 370px / 2);
  }
  @media ${device.iphone6} {
    max-width: 400px;
    margin-left: calc(50% - 400px / 2);
  }
  @media ${device.mobileL} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }
  @media ${device.tablet} {
    max-width: 500px;
    margin-left: calc(50% - 500px / 2);
  }
  @media ${device.tabletPro} {
    max-width: 600px;
    margin-left: calc(50% - 600px / 2);
  }
  @media ${device.fullHDTV720p} {
    max-width: 600px;
    margin-left: calc(50% - 600px / 2);
    height: 1000px;
  }

  @media ${device.desktop} {
    max-width: 600px;
    margin-left: calc(50% - 600px / 2);
  }
`;
const ScorePage = () => {
  const { id } = useParams();
  const [shareImage, setShareImage] = React.useState(`${id}.png`);
  /**
   * Absolute URLs are required for twitter/OG tags
   * > if your image is not showing, is it accessible on a URL that is not blocked by your robots.txt file?
   * > Does it conform to our size constraints? Are you using an absolute and full URL (including the http protocol piece), not a relative one?
   *
   * Using the `prerender-spa-plugin` for statically generating this page means that URLs cannot be generated for each site (non-production/production)
   * without separate build artefacts.  This is a bad idea - a single build should be used for production/non-production.
   * Otherwise you are breaking your environment consistency.
   * This could be resolved by re-writing meta tags using Lambda@Edge, but is undesirable at this time due to the added complexity.
   *
   * @see https://twittercommunity.com/t/card-error-unable-to-render-or-no-image-read-this-first/62736
   * @see frontend/prerender-config.js
   * @see frontend/craco.config.js
   */
  const fqUrl = "https://dream.devops.games";
  React.useEffect(() => {
    import(`../../assets/images/scoreScreenshots/${id}.png`)
      .then(image => {
        setShareImage(image);
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  }, [id]);

  if (shareImage) {
    return (
      <Container>
        <HelmetTags imageURL={`${fqUrl}${Object.values(shareImage)[0]}`} imageId={id} domainUrl={fqUrl} />
        <ImageContainer>
          <a href="https://dream.devops.games">
            <Image src={`${Object.values(shareImage)[0]}`} />
          </a>
        </ImageContainer>
        <Footer>
          <a href="https://dream.devops.games">
            <NextButton buttonIcon={true} text="Play Now" />
          </a>
        </Footer>
      </Container>
    );
  } else {
    return <h1>Error</h1>;
  }
};

export default ScorePage;
