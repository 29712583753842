import { IMetrics } from "../../interfaces";

export const getMetricPerformanceText = (metric: keyof IMetrics, metrics: IMetrics) => {
  let ScoreText = "";
  if (metrics[metric] <= 50) {
    ScoreText = "Low";
    return ScoreText;
  } else if (metrics[metric] > 50 && metrics[metric] < 80) {
    ScoreText = "Medium";
    return ScoreText;
  } else if (metrics[metric] >= 80 && metrics[metric] < 90) {
    ScoreText = "High";
    return ScoreText;
  } else if (metrics[metric] >= 90) {
    ScoreText = "Elite";
    return ScoreText;
  } else {
    return ScoreText;
  }
};
