export const getMetricColour = (metric: string) => {
  if (metric === "Productivity") {
    return "#B30000";
  }
  if (metric === "Satisfaction") {
    return "#892AA9";
  }
  if (metric === "People") {
    return "#0050C0";
  }
  if (metric === "Stability") {
    return "#006411";
  }
};
