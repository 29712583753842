import * as React from "react";
import { Event, IMetrics } from "../../interfaces";
import EventSelection from "./EventSelection";
import { GenerateID } from "../../shared/helpers/testIdGenerator";
import { IEventsSelection } from "../domain_model/eventList";

interface Props {
  events: Event[];
  metrics: IMetrics;
  isTutorial: boolean;
  selectedEvents: IEventsSelection[];
  eventSet: (events: Event[], newMetrics: IMetrics, eventsState: IEventsSelection[]) => void;
  onExitTutorial: () => void;
  page?: string;
}

const Events: React.FC<Props> = ({ events, isTutorial, onExitTutorial, metrics, eventSet, selectedEvents }) => {
  const [currentEvents, setCurrentEvents] = React.useState(events);
  const [currentMetrics] = React.useState(metrics);
  return (
    <>
      {events.length > 0 ? (
        <EventSelection
          events={currentEvents}
          metrics={currentMetrics}
          updateEvents={setCurrentEvents}
          selectedEvents={selectedEvents}
          isTutorial={isTutorial}
          eventSet={eventSet}
          onExitTutorial={onExitTutorial}
        />
      ) : (
        <p data-testid={GenerateID("No Events!")}>"No Events!"</p>
      )}
    </>
  );
};

export default Events;
