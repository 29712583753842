import * as React from "react";
import WhatAreMetrics from "./WhatAreMetrics";
import { IMetrics } from "../../interfaces";
import { Company } from "../../app";
import MetricsReport from "./MetricsReport";
import { IYearReport } from "../../interfaces/calendarReport";
interface Props {
  companyName: Company;
  playerName: string;
  metrics: IMetrics;
  onDone: () => void;
  page: string;
  tutorial: boolean;
  onExitTutorial: () => void;
  calendarReport: IYearReport;
  updateCalendarReport: (calendarUpdates: IYearReport) => void;
  getArrowImage: (metric: keyof IMetrics) => string;
}

const Metrics: React.FC<Props> = ({
  companyName,
  playerName,
  metrics,
  onDone,
  onExitTutorial,
  page,
  tutorial,
  calendarReport,
  updateCalendarReport,
  getArrowImage,
}) => {
  switch (page) {
    case "WhatAreMetrics":
      return (
        <WhatAreMetrics
          companyName={companyName}
          onDone={onDone}
          onClose={onExitTutorial}
          isTutorial={tutorial}
          metrics={metrics}
        />
      );
    case "QuarterlyReport":
      return (
        <MetricsReport
          companyName={companyName}
          playerName={playerName}
          metrics={metrics}
          timelineReport={calendarReport}
          updateTimeline={updateCalendarReport}
          getArrowImage={getArrowImage}
        />
      );

    default:
      return <h1>Ooops. You're not meant to be here.</h1>;
  }
};

export default Metrics;
