import React from "react";
import styled from "styled-components";

const Label = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #4f4f4f;
`;

interface props {
  qNumber: number;
  checked: boolean;
}

const QuarterCheckBox = ({ qNumber, checked }: props) => {
  return (
    <div data-testid="circles">
      <span
        data-testid={`${qNumber}-${checked}`}
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: checked ? "#004A64" : "#ccc",
          borderRadius: " 50%",
          display: "block",
        }}
      />
      <Label data-testid={`${qNumber}-label`}>Q{qNumber}</Label>
    </div>
  );
};

export default QuarterCheckBox;
