import React from "react";
import { Progress, Grid, Image, Accordion, Icon } from "semantic-ui-react";
import { IMetrics } from "../../interfaces";
import styled from "styled-components";
import { IYearReport } from "../../interfaces/calendarReport";
import { getMetricPerformanceText } from "../../gameEngine/domain_model/getMetricPerformanceText";
import { getMetricColour } from "../domain_model/getProgressBarColour";

interface IAccordion {
  metrics: IMetrics;
  report: IYearReport;
  getArrowImage: (metric: keyof IMetrics) => string;
  page?: string;
}

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${props => props.color || "green"} !important;
  }
`;

const ProgressBarStyle = styled.div`
   {
    width: 100%;
    margin: 1rem 10rem 1rem 0;
  }
`;

const MetricsProgressArrow = styled.div``;
const PerformanceText = styled.div`
  display: grid;
  justify-content: center;
`;

const MetricsGrid: React.FC<IAccordion> = ({ metrics, report, getArrowImage, page }: IAccordion) => {
  const [activeIndex, setActiveState] = React.useState(0);

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveState(newIndex);
  };
  // Similar, but different to the object formatter in Metrics.tsx in game engine
  const formatObjectKeyMetricsGrid = (string: string) => {
    let updatedString = string.charAt(0).toUpperCase() + string.slice(1).replace(/([A-Z])/g, " $1");
    if (updatedString === "Customer Satisfaction") {
      updatedString = updatedString.split(" ", 2)[1];
    }
    return updatedString;
  };

  return (
    <Accordion
      fluid
      styled
      style={{ marginBottom: "1rem", display: "block", boxShadow: "none" }}
      data-testid="metrics-accordion"
    >
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
        style={{ display: "flex", margin: "1rem", color: "black" }}
      >
        <Icon name={activeIndex === 0 ? "chevron down" : "chevron right"} size="large" />
        Performance Metrics
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0} data-testid={"metric-content"}>
        {Object.entries(metrics).map((metric, index) => {
          return (
            <div key={metric.toString()}>
              <Grid data-testid={`${formatObjectKeyMetricsGrid(metric[0])}-metric`} style={{ width: "300px" }}>
                <div style={{ marginTop: "1.6rem", width: "90px" }}>{formatObjectKeyMetricsGrid(metric[0])}</div>
                <Grid.Column style={{ width: "50%" }}>
                  <ProgressBarStyle>
                    <StyledProgressBar
                      percent={metric[1]}
                      size={"tiny"}
                      data-testid={`${formatObjectKeyMetricsGrid(metric[0])}-bar`}
                      color={getMetricColour(formatObjectKeyMetricsGrid(metric[0]))}
                      style={{ marginBottom: "1em" }}
                    />
                    <PerformanceText data-testid={`${formatObjectKeyMetricsGrid(metric[0])}-performance-text`}>
                      {(report.currentQuarter !== 0 &&
                        getMetricPerformanceText(metric[0] as keyof IMetrics, metrics)) ||
                        (page === "MyScore" && getMetricPerformanceText(metric[0] as keyof IMetrics, metrics))}
                    </PerformanceText>
                  </ProgressBarStyle>
                </Grid.Column>
                {getArrowImage(metric[0] as keyof IMetrics) && (
                  <Grid.Column style={{ width: "20%", marginTop: "1rem" }}>
                    <MetricsProgressArrow>
                      <Image
                        src={getArrowImage(metric[0] as keyof IMetrics)}
                        data-testid={`${formatObjectKeyMetricsGrid(metric[0])}-arrowImage`}
                        alt={
                          getArrowImage(metric[0] as keyof IMetrics).includes("Trending-up")
                            ? "Performance improved indicator"
                            : "Performance decreased indicator"
                        }
                      />
                    </MetricsProgressArrow>
                  </Grid.Column>
                )}
              </Grid>
            </div>
          );
        })}
      </Accordion.Content>
    </Accordion>
  );
};

export default MetricsGrid;
