import { Score } from "../../../../BackEnd/BuildBackend/PlayerScores/RecordScore/interfaces";

const axios = require("axios");
export const getRankingMessage = (ApiURL: string, data: Score, setRankingMessage: Function) => {
  axios
    .post(ApiURL, data)
    .then(function (response: any) {
      setRankingMessage(response.data);
    })
    .catch(function (error: string) {
      console.log(error);
    });
};
