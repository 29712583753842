import * as React from "react";
import { Button, Grid, Radio, Icon } from "semantic-ui-react";
import initiatives from "../domain_model/initiatives.json";
import { GenerateID } from "../../shared/helpers/testIdGenerator";
import { addInitiative } from "../domain_model/updateInitiativeList";
import { initiativeCost } from "../domain_model/updateBudget";
import ProgressBar from "../components/ProgressBar";
import ErrorMessage from "../../shared/components/ErrorMessage";
import { IInitiativeSelection } from ".";
import ReactGA from "react-ga4";
import PageLayout from "../../shared/components/PageTemplate";
import { StyledInitiativeContainer } from "../components/StyledInitiativeContainer";
import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";

const importedInitiatives = initiatives;

const Header = styled.div`
  display: flex;
  margin-top: 1em;
  margin-left: 6px;
  > h3 {
    margin-left: 10px;
  }
  @media ${device.tablet} {
    margin-left: 6em;
  }
  @media ${device.laptopL} {
    margin-left: 7em;
  }
  @media ${device.fullHDTV1080p} {
    margin-left: 9em;
  }
  @media ${device.desktopL} {
    margin-left: 13em;
  }
`;

const CloseButton = styled.div`
  margin-left: 85%;
  @media ${device.tablet} {
    margin-left: 90%;
  }
  @media ${device.laptop} {
    margin-left: 92%;
  }
  @media ${device.fullHDTV720p} {
    margin-left: 91%;
  }
  @media ${device.fullHDTV1080p} {
    margin-left: 93%;
  }
  @media ${device.desktop} {
    margin-left: 95%;
  }
`;

const ButtonFocus = styled(Button)`
  :focus {
    border: 2px solid #27c6ff !important;
    transition: border 0.2s;
  }
`;

const DescriptionText = styled.div`
  margin-bottom: 2em;
  margin-top: 2em;

  @media ${device.mobileS} {
    > p {
      margin-left: 1em;
    }
  }
  @media ${device.tablet} {
    > p {
      text-align: left;
      margin-left: 18%;
      margin-right: 10%;
    }
  }
`;

const ProgressBarContainer = styled.div`
  @media ${device.tablet} {
    margin-left: 14%;
    margin-right: 6%;
  }
  @media ${device.desktopL} {
    margin-left: 17%;
    margin-right: 6%;
  }
`;

interface initiativeProps {
  selectedInitiative: string;
  selectedInitiatives: IInitiativeSelection[];
  setSelectedInitiatives: (listOfSelectedInitiatives: IInitiativeSelection[]) => void;
  removeInitiative: () => void;
  updateBudget: (selectedOptionCost: number) => void;
  onClose: () => void;
  setPage: (pageToShow: string) => void;
  setPreviousSelectedOption: (previousChoice: string) => void;
  isTutorial: boolean;
  optionCost: number;
  initiativeMinCost: number;
  totalBudget: number;
  budgetToSpend: number;
  previousCost: number;
  currentYear: number;
  previousSelectedOption: string;
  fundedOption: string;
}

const InitiativeOptions: React.FC<initiativeProps> = ({
  selectedInitiative,
  selectedInitiatives,
  setSelectedInitiatives,
  setPage,
  onClose,
  totalBudget,
  isTutorial,
  optionCost,
  initiativeMinCost,
  budgetToSpend,
  previousCost,
  updateBudget,
  currentYear,
  previousSelectedOption,
  setPreviousSelectedOption,
  removeInitiative,
  fundedOption,
}) => {
  const [optionSelected, setOption] = React.useState(previousSelectedOption);
  const [currentBudget, setCurrentBudget] = React.useState(budgetToSpend);
  const [lastChoiceCost, setLastChoiceCost] = React.useState(previousCost);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [pressed, setPressed] = React.useState(false);
  const [clickedOption, setClickedOption] = React.useState("");
  const [timeLoaded] = React.useState(new Date().getTime());

  const handleOptionClick = (selectedOption: string, indexOfOption: number) => {
    const cost = initiativeCost(indexOfOption, initiativeMinCost, optionCost);
    const updatedBudget = currentBudget - cost + lastChoiceCost;
    if (updatedBudget >= 0) {
      setLastChoiceCost(cost);
      setCurrentBudget(updatedBudget);
      setOption(selectedOption);
      setErrorMessage("");
    } else {
      setErrorMessage("Sorry! You don't have enough budget");
      setOption("");
    }
  };

  const valueRef = React.useRef("");
  React.useEffect(() => {
    valueRef.current = clickedOption;
  }, [clickedOption]);

  React.useEffect(() => {
    return () => {
      ReactGA.event({
        category: `Initiative Option ${valueRef.current} duration`,
        action: ((new Date().getTime() - timeLoaded) / 1000).toString(),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelInitiative = React.useCallback(() => {
    const fundedOptionSet = fundedOption !== "";
    if (fundedOptionSet) {
      const UpdatedInitiativeList = addInitiative({
        selectedInitiatives,
        selectedInitiative,
        optionSelected: fundedOption,
        currentYear,
      });
      setSelectedInitiatives(UpdatedInitiativeList);
      setPage("selectInitiative");
    } else {
      removeInitiative();
      setPage("selectInitiative");
    }
  }, [
    currentYear,
    removeInitiative,
    selectedInitiative,
    selectedInitiatives,
    setPage,
    setSelectedInitiatives,
    fundedOption,
  ]);

  return (
    <PageLayout
      disabled={optionSelected === "" && previousSelectedOption === ""}
      onClick={() => {
        ReactGA.event({
          category: "Initiatives",
          action: `Initiative option picked: ${optionSelected}`,
        });
        updateBudget(currentBudget);
        const UpdatedInitiativeList = addInitiative({
          selectedInitiatives,
          selectedInitiative,
          optionSelected,
          currentYear,
        });
        setSelectedInitiatives(UpdatedInitiativeList);
        setPage("selectInitiative");
        setPreviousSelectedOption(optionSelected);
      }}
      includeFooter={true}
      text="Confirm"
      testId="confirmBtn"
      isInitiativeButton={true}
      buttonStyle={{
        margin: "1rem auto",
        background: "#005E80",
        color: "#FFFFFF",
        maxWidth: "530px",
        borderRadius: "10px",
      }}
      buttonIcon={false}
    >
      <StyledInitiativeContainer>
        <CloseButton>
          <ButtonFocus
            icon
            data-testid="exitBtn"
            style={{
              marginTop: "10px",
              background: "#005E80",
              color: "#FFFFFF",
            }}
            alt="Close button. White letter X inside red square."
            onClick={() => {
              cancelInitiative();
            }}
          >
            <Icon name="close" />
          </ButtonFocus>
        </CloseButton>
        {importedInitiatives.map(initiativeIterator => {
          return (
            initiativeIterator.initiative === selectedInitiative && (
              <div key={selectedInitiative}>
                <Header>
                  <img src={initiativeIterator.image} alt="initiative icon" />
                  <h3 data-testid="chosen-initiative">{selectedInitiative}</h3>
                </Header>
                <DescriptionText>
                  <p>{initiativeIterator.screenText}</p>
                </DescriptionText>
                <div>
                  <p style={{ textAlign: "center" }}>YOUR BUDGET </p>
                  <ProgressBarContainer>
                    <ProgressBar
                      annualBudget={totalBudget}
                      budgetToSpend={Number(Math.round(currentBudget))}
                      data-testid="budgetBar"
                    />
                  </ProgressBarContainer>
                  {errorMessage && <ErrorMessage textMessage={errorMessage} />}
                  <Grid.Column style={{ display: "grid", justifyContent: "center" }}>
                    {initiativeIterator.label.map(option => {
                      return (
                        <Radio
                          label={option}
                          alt={option}
                          key={GenerateID(option)}
                          data-testid={GenerateID(option)}
                          checked={optionSelected === option || previousSelectedOption === option}
                          value={option}
                          style={{
                            display: "flex",
                            background:
                              optionSelected === option ||
                              previousSelectedOption === option ||
                              (pressed && clickedOption === option)
                                ? "#ffe4c4"
                                : "#FFFFFF",
                            borderRadius: "1em",
                            padding: "15px",
                          }}
                          onClick={() => {
                            handleOptionClick(option, initiativeIterator.label.indexOf(option));
                            setPressed(true);
                            setPreviousSelectedOption("");
                            setClickedOption(option);
                          }}
                        />
                      );
                    })}
                  </Grid.Column>
                </div>
              </div>
            )
          );
        })}
      </StyledInitiativeContainer>
    </PageLayout>
  );
};

export default InitiativeOptions;
