import React from "react";
import { Progress } from "semantic-ui-react";
import styled from "styled-components";

const ProgressContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr auto;
  align-items: baseline;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: 0rem;
  grid-gap: 1rem;
  line-height: 0.9em;
`;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${props => props.color || "orange"} !important;
  }
  & > .bar .progress {
    color: rgb(255, 255, 255) !important;
  }
`;

interface IProgressBar {
  annualBudget: number;
  budgetToSpend: number;
}

const ProgressBar = ({ annualBudget, budgetToSpend }: IProgressBar) => {
  return (
    <>
      <ProgressContainer data-testid="progressBar">
        <StyledProgressBar progress percent={budgetToSpend} size={"small"} color={"#005E80"} data-testid="budget-bar" />
        <strong data-testid="budget">
          ${annualBudget / 1000000}
          {budgetToSpend > 100 ? "M+" : "M"}
        </strong>
      </ProgressContainer>
      {budgetToSpend > 100 && (
        <p data-testid="budget-message" style={{ color: "green" }}>
          Your Initiative strategy has increased your budget!
        </p>
      )}
    </>
  );
};

export default ProgressBar;
