import styled from "styled-components";
import React, { FC } from "react";

import NextButton from "./NextButton";

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Footer = styled.div`
  max-width: 100%;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
`;

const Body = styled.div`
  max-width: 100%;
  margin-bottom: 5rem;
`;

interface ILayoutProps {
  testId?: string;
  disabled?: boolean;
  text?: string;
  onClick?: () => void;
  includeFooter?: boolean;
  buttonIcon: boolean;

  // children is implicitly imported as part of FC
}

const PageTemplate: FC<ILayoutProps> = ({ children, testId, disabled, text, onClick, includeFooter, buttonIcon }) => (
  <Container>
    <Body>{children}</Body>
    {includeFooter && (
      <Footer>
        <NextButton testId={testId} disabled={disabled} text={text} onClick={onClick} buttonIcon={buttonIcon} />
      </Footer>
    )}
  </Container>
);

export default PageTemplate;
