import { IMetrics, nodes, IChoice } from "../../interfaces";
import { calculateMetrics, EngineVisitor } from "./scoreEngine/functions";
import { limitMetricsTo100 } from "../../shared/helpers/capMetricsAt100";

export const calculateEventMetrics = (choice: IChoice, metrics: IMetrics, engineVisitor?: EngineVisitor) => {
  let newMetrics: IMetrics = metrics;
  choice.nodes.forEach(effect => {
    const node = Object.keys(effect)[0] as nodes;
    const value = Object.values(effect)[0] || 0;
    const multiplier = value * 1;
    newMetrics = calculateMetrics(node, multiplier, engineVisitor)(newMetrics);
  });
  newMetrics = limitMetricsTo100(newMetrics);
  return newMetrics;
};
