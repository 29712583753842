import * as React from "react";
import JobOffer from "./JobOffer";
import CompanySelection from "./CompanySelection";
import WelcomeCIO from "./WelcomeCIO";
import { useState } from "react";
import { GameConfig } from "../../app";

interface Props {
  state: GameConfig;
  updateGameState: (gameState: GameConfig) => void;
  onSetupCompleted: () => void;
  onExitTutorial: () => void;
  page?: string;
}

const CompanySelect: React.FC<Props> = ({ state, updateGameState, onExitTutorial, onSetupCompleted, page }) => {
  const [pageToShow, setPageToShow] = useState(page || "jobOffer");

  switch (pageToShow) {
    case "jobOffer":
    default:
      return (
        <JobOffer
          playerName={state.name}
          onDone={() => setPageToShow("companySelect")}
          onClose={onExitTutorial}
          isTutorial={state.tutorial}
        />
      );
    case "companySelect":
      return (
        <CompanySelection
          setCompanyName={(companyName: GameConfig["companyName"]) => updateGameState({ ...state, companyName })}
          onDone={() => setPageToShow("welcomeCIO")}
          onClose={onExitTutorial}
          isTutorial={state.tutorial}
          companyName={state.companyName}
        />
      );
    case "welcomeCIO":
      return (
        <WelcomeCIO
          playerName={state.name}
          companyName={state.companyName}
          onDone={() => onSetupCompleted()}
          onClose={onExitTutorial}
          isTutorial={state.tutorial}
        />
      );
  }
};

export default CompanySelect;
