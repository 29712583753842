import React from "react";
import { IMetrics } from "../../interfaces/metrics";
import styled from "styled-components";
import { getScoreText, getAverageScore } from "../domain_model/getScore";
import ScorePerformancePanel from "../components/ScorePerformancePanel";
import eliteScore from "../../../src/assets/images/score/Score-Elite.svg";
import highScore from "../../../src/assets/images/score/Score-High.svg";
import MedScore from "../../../src/assets/images/score/Score-Med.svg";
import LowScore from "../../../src/assets/images/score/Score-Low.svg";
import { Image, Container } from "semantic-ui-react";
import Links from "../components/LinkContainer";
import MetricsGrid from "../components/MetricsGrid";
import { IYearReport } from "../../interfaces/calendarReport";
import { IInitiativeSelection } from "../../initiatives/pages";
import { generatePlayData } from "../../shared/helpers/generatePlaythroughData";
import { IEventsSelection } from "../../events/domain_model/eventList";
import ReactGA from "react-ga4";
import YearlyTrendsPanel from "../../gameEngine/components/YearlyTrendsPanel";
import { Company } from "../../app";
import PageTemplate from "../../shared/components/PageTemplate2";
import WideNavigation from "../../shared/components/WideNavigation";
import { device } from "../../shared/helpers/deviceConfig";
import SocialMedia from "../components/SocialMedia";
import BagelCompanyImage from "../../assets/images/BagelCompanyImage.svg";
import ElephantCompanyImage from "../../assets/images/ElephantCompanyImage.svg";
import GnomeCompanyImage from "../../assets/images/GnomeCompanyImage.svg";
import { GenerateID } from "../../shared/helpers/testIdGenerator";

import { Score } from "../../../../BackEnd/BuildBackend/PlayerScores/RecordScore/interfaces";
import { getRankingMessage } from "../domain_model/getRankingMessage";

const ScoreImage = styled(Image)`
  display: flex;
  justify-content: center;
  padding: 1rem;

  @media ${device.mobileS} {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  @media ${device.mobileM} {
    margin-left: 1.5rem;
    margin-right: 0;
  }
  @media ${device.iphone6} {
    margin-left: 2.5rem;
    margin-right: 0;
  }
  @media ${device.mobileL} {
    margin-left: 2.5rem;
    margin-right: 0;
  }
  @media ${device.tablet} {
    margin-right: 0;
    margin-left: 0;
  }
  @media ${device.tabletPro} {
    margin-right: 5rem;
    margin-right: 0;
  }
  @media ${device.desktop} {
    margin: 0;
  }
`;
const CompanyImage = styled(Image)`
  margin: 0;
  padding: 0;
  left: 140px;
`;
const ScoreMessage = styled.div`
  font-family: Mukta;
  font-style: normal;
  color: #333333;
  text-align: center;
  display: flex;
  justify-content: center;

  margin: 1rem;
  padding: 1rem;
  > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    width: 500px;
    height: auto;
  }
`;

const ContentContainer = styled.div`
  @media ${device.mobileS} {
    display: grid;
    justify-content: center;
    margin: auto 1rem;
  }
  @media ${device.mobileM} {
    display: grid;
    justify-content: center;
    margin: auto 1rem;
  }
  @media ${device.mobileL} {
    display: grid;
    justify-content: center;
    margin: auto 1rem;
  }
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }
`;

const ScoreContainer = styled.div``;
interface Props {
  metrics: IMetrics;
  onDone: () => void;
  report: IYearReport;
  initiativesState: IInitiativeSelection[];
  eventState: IEventsSelection[];
  name: string;
  getArrowImage: (metric: keyof IMetrics) => string;
  company: Company;
  page: string;
}

const LowMessage = <p>You are awarded with Poop and Flies</p>;
const MedMessage = <p>You are awarded with a Wooden Spoon Firestorm</p>;
const HighMessage = <p>You are awarded with Second Place Sadness</p>;
const EliteMessage = (
  <p>
    Congratulations!
    <br />
    You have been approached by a headhunter to join a major corporation with a major salary boost!
  </p>
);

const startAgainClicked = (onDone: () => void) => {
  ReactGA.event({
    category: "End Game",
    action: "Final Score: Play Again clicked",
  });
  onDone();
};

const MyScore: React.FC<Props> = ({
  metrics,
  onDone,
  report,
  initiativesState,
  name,
  eventState,
  company,
  getArrowImage,
  page,
}: Props) => {
  const [scoreMessage, setScoreMessage] = React.useState<JSX.Element>();
  const [scoreImage, setScoreImage] = React.useState("");
  const [timeLoaded] = React.useState(new Date().getTime());
  const [score] = React.useState(getAverageScore(metrics));
  const [altText, setAltText] = React.useState("");
  const [companyImage, setCompanyImage] = React.useState("");
  const imageUrl = `${GenerateID(company)}-${score}`;

  const [RankingMessage, setRankingMessage] = React.useState("");

  const GetRankingMessageAPIURL = process.env.REACT_APP_API_URL || "https://api.dream-test.devops.games/score/score";
  const data: Score = {
    Score: score,
    Name: name,
    Company: company,
    Timestamp: new Date(Date.now()),
  };

  React.useEffect(() => {
    getRankingMessage(GetRankingMessageAPIURL, data, setRankingMessage);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      ReactGA.event({
        category: "Final score duration",
        action: ((new Date().getTime() - timeLoaded) / 1000).toString(),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LinkSelected = (pageName: string) => {
    ReactGA.event({
      category: "End game",
      action: `Click Through: ${pageName}`,
    });
  };
  React.useEffect(() => {
    generatePlayData(name, initiativesState, getAverageScore(metrics), eventState);
    switch (getScoreText(metrics)) {
      case "Low":
        setScoreMessage(LowMessage);
        setScoreImage(LowScore);
        setAltText("Poop and flies for low performance");
        break;
      case "Medium":
        setScoreMessage(MedMessage);
        setScoreImage(MedScore);
        setAltText("Fiery wooden spoon for medium performance");
        break;
      case "High":
        setScoreMessage(HighMessage);
        setScoreImage(highScore);
        setAltText("A small second place trophy surrounded by rainclouds for high performance");
        break;
      case "Elite":
        setScoreMessage(EliteMessage);
        setScoreImage(eliteScore);
        setAltText("Large golden trophy surrounded by shooting stars for elite performance");
        break;
    }
  }, [metrics, name, initiativesState, eventState, company, score]);

  React.useEffect(() => {
    switch (company) {
      case Company["Blockchain Bagels"]:
        setCompanyImage(BagelCompanyImage);
        break;
      case Company["Networked Gnomes"]:
        setCompanyImage(GnomeCompanyImage);
        break;
      case Company["Elephant Enterprises"]:
        setCompanyImage(ElephantCompanyImage);
        break;
    }
  }, [company]);

  return (
    <PageTemplate
      testId="endGame-StartAgain"
      text="Play Again"
      onClick={() => startAgainClicked(onDone)}
      includeFooter={true}
      buttonIcon={false}
    >
      <WideNavigation title=" CIO Scorecard">
        <ContentContainer>
          <ScoreImage>
            <Image src={scoreImage} alt={altText} />
          </ScoreImage>
          <ScoreContainer>
            <ScorePerformancePanel metrics={metrics} />
          </ScoreContainer>
        </ContentContainer>
        <Container data-testid="company_name" style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
          <CompanyImage
            src={companyImage}
            data-testid={`${GenerateID(company)}`}
            style={{ display: "initial", left: "-30px" }}
          />
          <div style={{ textAlign: "left", maxWidth: "400px", alignSelf: "center" }}>
            <p style={{ fontWeight: "bold", fontSize: "18px", lineHeight: "26px" }}>{company}</p>
          </div>
        </Container>

        <ScoreMessage>{scoreMessage}</ScoreMessage>
        {RankingMessage === "You didn't make the leaderboard, can you do better next time?" ? (
          <ScoreMessage data-testid="rankingMessage">
            <p>
              You didn't make the leaderboard,
              <a
                href="https://devops.games/pages/improveYourSDO.html"
                onClick={() => {
                  LinkSelected("Learn more about DORA State of DevOps");
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> can you do better next time?</span>
              </a>
            </p>
          </ScoreMessage>
        ) : (
          <ScoreMessage data-testid="rankingMessage">{RankingMessage}</ScoreMessage>
        )}
      </WideNavigation>

      <ContentContainer style={{ maxWidth: "700px", margin: "auto", justifyContent: "left" }}>
        <MetricsGrid metrics={metrics} report={report} getArrowImage={getArrowImage} page={page} />
        <YearlyTrendsPanel report={report} company={company} />
      </ContentContainer>
      <SocialMedia company={company} score={score} imageURL={imageUrl} />

      <Links />
    </PageTemplate>
  );
};

export default MyScore;
