import { Event } from "../../interfaces";
import { shuffleArray, pickRandomEventFromArray } from "../../shared/helpers/arrayTools";

export const events: Event[] = require("./events.json");
export const tutorialEvents: Event[] = require("./tutorialEvents.json");

export function startingEvents(isTutorial: boolean) {
  if (isTutorial) {
    return tutorialEvents;
  }

  return randomEvents();
}

export function eventOccurs(events: Event[]) {
  return pickRandomEventFromArray(events);
}

export const returnUnusedEvents = (EventList: Event[], usedEvent: Event) => {
  return EventList.filter(event => event.name !== usedEvent.name);
};

function randomEvents() {
  return shuffleArray(events);
}
