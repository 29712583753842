import React from "react";
import { Container } from "semantic-ui-react";
import QuarterCheckBox from "./QuarterCheckBox";
import { IQuarter } from "../../interfaces/calendarReport";

interface Iprops {
  quarters: IQuarter[];
}

const Timeline = ({ quarters }: Iprops) => {
  return (
    <Container date-testid="timeline" style={{ marginBottom: "2rem", marginTop: "1rem" }}>
      <hr data-testid="horizontalLine" />
      <div
        data-testid="timeline"
        style={{
          marginLeft: "-10px",
          marginRight: "-10px",
          marginTop: "-18px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {quarters.map((quarter, index) => {
          return <QuarterCheckBox qNumber={quarters.indexOf(quarter) + 1} key={index} checked={quarter.quarter} />;
        })}
      </div>
    </Container>
  );
};
export default Timeline;
