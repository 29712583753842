import { IYearState, IYearReport } from "../../interfaces/calendarReport";
import { IMetrics } from "../../interfaces";

export const getYearQuarter = (Report: IYearReport, metrics: IMetrics) => {
  return getCurrentYear(Report, metrics).quarters[Report.currentQuarter];
};

export const getCurrentYear = (Report: IYearReport, metrics: IMetrics) => {
  let thisyear: IYearState = {
    count: 0,
    quarters: [
      { quarter: false, metrics: metrics },
      { quarter: false, metrics: metrics },
      { quarter: false, metrics: metrics },
      { quarter: false, metrics: metrics },
    ],
  };
  Report.calendarYearsInfo.map((year: IYearState) => {
    if (year.count === Report.currentYear) {
      thisyear = year;
    }
    return thisyear;
  });
  return thisyear;
};
