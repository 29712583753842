import * as React from "react";
import { Container, Button, Icon, Grid } from "semantic-ui-react";

import { GenerateID } from "../../shared/helpers/testIdGenerator";
import ProgressBar from "../components/ProgressBar";
import ErrorMessage from "../../shared/components/ErrorMessage";
import { findCurrentYearInitiatives } from "../domain_model/findCurrentYearInitiatives";
import { findInitiative } from "../domain_model/findInitiative";
import Locked from "../../assets/images/locked.svg";
import { IInitiativeSelection } from ".";
import { calculateCostPerOption, findIndexOfOption, initiativeCost, getMinCost } from "../domain_model/updateBudget";
import ReactGA from "react-ga4";
import { getFundLevelMessage } from "../domain_model/getFundLevelMessage";
import { disableInitiativeButton } from "../domain_model/disableInitiativeButton";
import styled from "styled-components";
import PageLayout from "../../shared/components/PageTemplate";
import { device } from "../../shared/helpers/deviceConfig";
import { Initiative } from "../../interfaces";

interface Props {
  totalBudget: number;
  availableCredit: number;
  selectedInitiatives: IInitiativeSelection[];
  currentYear: number;
  setPlayerInitiative: (currentSelectedInitiative: string) => void;
  onInitiativeClick: (playerInitiative: IInitiativeSelection["initiativeName"]) => void;
  onDeleteInitiativeClick: (playerInitiative: IInitiativeSelection["initiativeName"]) => void;
  updateBudget: (selectedOptionCost: number) => void;
  setPage: (pageToShow: string) => void;
  onClose: () => void;
  onDone: () => void;
  isTutorial: boolean;
  hasBudget: boolean;
  errorMessage: string;
  initiativeFundLimit: number;
  listInitiatives: Initiative[];
}

const TrashIcon = styled.div`
  display: block;
  margin-left: 194px;
`;

const InitiativeButtonContainer = styled.div`
  @media ${device.mobileS} {
    display: block;
    flex-direction: column;
  }
  @media ${device.mobileL} {
    display: block;
    flex-direction: column;
    width: 400px;
    padding: 30px;
    margin: auto;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-around;
    width: unset;
    padding: unset;
  }
  @media ${device.fullHDTV720p} {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-around;
    width: unset;
    padding: unset;
    &:last-child {
      margin-bottom: 100px;
    }
  }
  @media ${device.fullHDTV1080p} {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-around;
    width: unset;
    padding: unset;
    &:last-child {
      margin-bottom: unset;
    }
  }
  {
    padding-bottom: 75px;
  }
`;

const ButtonFocus = styled(Button)`
  :focus {
    border-color: #006b92 !important;
    transition: border-color 0.2s;
  }
`;

export const getIcons = (cost: number, initiative: string) => {
  const id = GenerateID(initiative);

  return Array.from({ length: cost }, (_, i) => (
    <Icon
      key={i}
      data-testid={`${id}-icon${i}`}
      name="dollar sign"
      size="small"
      style={{
        background: "#000000",
        color: "#FFFFFF",
        borderRadius: "10px",
      }}
    />
  ));
};

const InitiativeSelection = ({
  selectedInitiatives,
  setPlayerInitiative,
  setPage,
  onDone,
  onClose,
  onInitiativeClick,
  onDeleteInitiativeClick,
  updateBudget,
  isTutorial,
  totalBudget,
  availableCredit,
  errorMessage,
  currentYear,
  initiativeFundLimit,
  listInitiatives,
}: Props) => {
  const [currentInitiative, setCurrentInitiative] = React.useState("");
  const currentYearInitiatives = findCurrentYearInitiatives(selectedInitiatives, currentYear);
  const previousYearsInitiatives = selectedInitiatives.filter(
    initiative => !currentYearInitiatives.includes(initiative),
  );
  const [currentBudget, setCurrentBudget] = React.useState(availableCredit);
  const [timeLoaded] = React.useState(new Date().getTime());
  let valueRef = React.useRef("");

  React.useEffect(() => {
    return () => {
      ReactGA.event({
        category: `Initiative Selection ${valueRef.current} duration`,
        action: ((new Date().getTime() - timeLoaded) / 1000).toString(),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Initiatives"
      confirmExit={onClose}
      isTutorial={isTutorial}
      includeFooter={true}
      includeNav={true}
      icon="close"
      testId="confirmBtn"
      disabled={false}
      text="Start the Year"
      onClick={onDone}
      isInitiativeButton={true}
      buttonStyle={{
        margin: "1rem auto",
        background: "#005E80",
        color: "#FFFFFF",
        maxWidth: "530px",
        borderRadius: "10px",
        border: "2px solid #005E80",
      }}
      buttonIcon={false}
    >
      <Container style={{ textAlign: "center" }}>
        <p>Your Budget</p>
        <ProgressBar
          annualBudget={totalBudget}
          budgetToSpend={Number(Math.round(currentBudget))}
          data-testid="budgetBar"
        />
        {!isTutorial && (
          <p data-testid="counterInitiative-message">
            {getFundLevelMessage(currentYearInitiatives, initiativeFundLimit)}
          </p>
        )}
        <InitiativeButtonContainer data-testid="initiativesContainer">
          {listInitiatives.map(initiativeIterator => {
            const disabledIndicator = disableInitiativeButton(
              isTutorial,
              initiativeIterator.initiative,
              currentYearInitiatives,
              previousYearsInitiatives,
              initiativeFundLimit,
            );
            return (
              <ButtonFocus
                key={`${initiativeIterator.initiative}-container`}
                data-testid={`${GenerateID(initiativeIterator.initiative)}-container`}
                style={{
                  border: "2px solid #E0E0E0",
                  borderRadius: "10px",
                  marginTop: "1em",
                  width: "260px",
                  background: findInitiative(currentYearInitiatives, initiativeIterator.initiative)
                    ? "#ffe4c4"
                    : "#FFFFFF",
                }}
                onClick={() => {
                  const playerInitiative = initiativeIterator.initiative;
                  valueRef.current = playerInitiative;
                  ReactGA.event({
                    category: "Initiatives",
                    action: `Initiative picked: ${playerInitiative}`,
                  });
                  setPlayerInitiative(playerInitiative);
                  setCurrentInitiative(playerInitiative);
                  onInitiativeClick(playerInitiative);
                }}
                disabled={disabledIndicator}
              >
                <div
                  key={GenerateID(initiativeIterator.initiative)}
                  data-testid={GenerateID(initiativeIterator.initiative)}
                  style={{
                    background: findInitiative(currentYearInitiatives, initiativeIterator.initiative)
                      ? "#ffe4c4"
                      : "#FFFFFF",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  <Grid>
                    <Grid.Column width={3} style={{ marginRight: "50px" }}>
                      {disabledIndicator ? (
                        <img src={Locked} style={{ display: "flex" }} alt="locked-icon" />
                      ) : (
                        <img src={initiativeIterator.image} style={{ display: "flex" }} alt="unlocked-icon" />
                      )}
                    </Grid.Column>
                    <Grid.Column width={9} data-testid="initiative">
                      {initiativeIterator.initiative}
                      {currentInitiative === initiativeIterator.initiative && errorMessage && (
                        <ErrorMessage textMessage={errorMessage} />
                      )}
                      {selectedInitiatives.map(initiativeState => {
                        return (
                          initiativeIterator.initiative === initiativeState.initiativeName &&
                          initiativeState.option && (
                            <p style={{ marginTop: ".5em", marginBottom: ".5rem" }} key={initiativeState.option}>
                              {initiativeState.option}
                            </p>
                          )
                        );
                      })}
                      <div style={{ marginTop: ".5em" }}>
                        {getIcons(initiativeIterator.initiativeCostIndicator, initiativeIterator.initiative)}
                      </div>
                    </Grid.Column>
                  </Grid>
                </div>
                {selectedInitiatives.map(initiativeState => {
                  return (
                    initiativeIterator.initiative === initiativeState.initiativeName &&
                    initiativeState.option && (
                      <TrashIcon key={GenerateID(`${initiativeIterator.initiative}-btn`)}>
                        <Button
                          icon
                          key={GenerateID(`${initiativeIterator.initiative}-btn`)}
                          data-testid={GenerateID(`${initiativeIterator.initiative}-btn`)}
                          style={{
                            padding: "5px",
                            color: "#FFFFFF",
                            background: "#E34A1A",
                            marginBottom: "5px",
                          }}
                          disabled={disabledIndicator}
                          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            onDeleteInitiativeClick(initiativeIterator.initiative);
                            const indexOfOption = findIndexOfOption(selectedInitiatives, initiativeIterator.initiative);
                            const optionCost = calculateCostPerOption(initiativeIterator.initiative);
                            const initiativeMinCost = getMinCost(initiativeIterator.initiative);
                            const cost = initiativeCost(indexOfOption, initiativeMinCost, optionCost);
                            const newBudget = currentBudget + cost;
                            setCurrentBudget(newBudget);
                            updateBudget(newBudget);
                            setPage("selectInitiative");
                            e.stopPropagation();
                          }}
                        >
                          <Grid style={{ flex: "inline-flex" }}>
                            <Grid.Column width={3} style={{ flex: "auto", alignSelf: "start" }}>
                              <Icon name="trash alternate" />
                            </Grid.Column>
                          </Grid>
                        </Button>
                      </TrashIcon>
                    )
                  );
                })}
              </ButtonFocus>
            );
          })}
        </InitiativeButtonContainer>
      </Container>
    </PageLayout>
  );
};

export default InitiativeSelection;
