const size = {
  mobileS: "320px",
  galaxyS: "360px",
  mobileM: "375px",
  iphone6: "414px",
  mobileL: "425px",
  tablet: "768px",
  tabletPro: "834px",
  laptop: "1024px",
  fullHDTV720p: "1280px",
  laptopL: "1440px",
  fullHDTV1080p: "1920px",
  desktop: "2560px",
  desktopL: "3840px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  galaxyS: `(min-width: ${size.galaxyS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  iphone6: `(min-width: ${size.iphone6})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletPro: `(min-width: ${size.tabletPro})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  fullHDTV1080p: `(min-width: ${size.fullHDTV1080p})`,
  fullHDTV720p: `(min-width: ${size.fullHDTV720p})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};
