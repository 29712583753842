import * as React from "react";
import { Image } from "semantic-ui-react";
import BlockchainBagelsImage from "../../assets/images/company_blockchain_bagels.svg";
import NetworkedGnomesImage from "../../assets/images/company_networked_gnomes.svg";
import ElephantEnterprisesImage from "../../assets/images/company_elephant_enterprises.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { GameConfig, Company } from "../../app";
import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";

export const blockchainBagelsBlurb = (
  <div data-testid="company-blurb">
    <p>
      We are an exciting new startup that solves the problem of sourcing sustainable bagels by using blockchain
      technology to verify the providence of your morning bagel.
    </p>
    <p>
      As a newly appointed CIO, you will need to implement a digital strategy to ship new features, scale and support a
      fragile digital system.
    </p>
  </div>
);

export const networkedGnomesBlurb = (
  <div data-testid="company-blurb">
    <p>
      We are a successful mid-tier, tech player producing data-collecting gnomes with real-time user data, video capture
      and IoT.
    </p>
    <p>
      As the CIO, we need direction on how to expand our team and products utilising new technologies like AI, machine
      learning and self-service data analytics, while maintaining all our current systems.
    </p>
  </div>
);
export const elephantEnterprisesBlurb = (
  <div data-testid="company-blurb">
    <p>We are the leading national supplier of bespoke pachyderm products to a billion dollar pet industry.</p>
    <p>
      We are slowly losing market share to smaller competitors, thus we see the need to reinvent ourselves as a digital
      company and become more nimble and innovative.
    </p>
    <p>Our new CIO will help set our vision and strategy so we can build capability and move faster.</p>
  </div>
);

interface Props {
  setCompanyName: (companyName: GameConfig["companyName"]) => void;
  isTutorial: boolean;
}
const CompanyCarousel = styled(Carousel)`
  .control-dots {
    margin: 10px -19px;
    border-radius: 0rem !important;
  }
  .control-dots .dot {
    border-radius: 50% !important;
  }
  .carousel.carousel-slider :first-child {
    border-radius: 0rem 1rem 1rem 0rem;
  }
  .carousel.carousel-slider :nth-child(3) {
    border-radius: 1rem 0rem 0rem 1rem;
  }
  .carousel.carousel-slider .control-arrow {
    background: darkgrey;

    @media ${device.mobileS} {
      margin-top: calc(50% - 128px);
      height: 190px;
      width: 35px;
    }
    @media ${device.mobileM} {
      margin-top: calc(50% - 158px);
      height: 230px;
      width: 40px;
    }

    @media ${device.iphone6} {
      margin-top: calc(50% - 170px);
      height: 250px;
      width: 40px;
    }
    @media ${device.tablet} {
      margin-top: calc(50% - 354px);
      height: 290px;
      width: 70px;
    }
    @media ${device.tabletPro} {
      margin-top: calc(50% - 389px);
      height: 320px;
      width: 70px;
    }
    @media ${device.laptop} {
      margin-top: calc(50% - 474px);
      height: 320px;
      width: 70px;
    }
    @media ${device.fullHDTV720p} {
      margin-top: calc(50% - 600px);
      height: 320px;
      width: 70px;
    }
    @media ${device.fullHDTV1080p} {
      margin-top: calc(50% - 517px);
      height: 320px;
      width: 70px;
    }
  }
`;
const BlurbContainer = styled.div`
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;
  @media ${device.tablet} {
    max-width: 400px;
  }
  @media ${device.tabletPro} {
    max-width: 500px;
  }
  @media ${device.fullHDTV720p} {
    max-width: 500px;
  }
  @media ${device.desktop} {
    max-width: 400px;
  }
  @media ${device.desktopL} {
    max-width: 500px;
  }
`;

const StyledImage = styled(Image)`
  margin-bottom: 3rem;
  background: #fff;

  @media ${device.mobileS} {
    width: 100% !important;
    margin-left: 0px !important;
  }
  @media ${device.mobileM} {
    width: 100% !important;
    margin-left: 0px !important;
  }
  @media ${device.mobileL} {
    width: 100% !important;
    margin-left: 0px !important;
  }
  @media ${device.galaxyS} {
    width: 100% !important;
    margin-left: 0px !important;
  }
  @media ${device.iphone6} {
    width: 100% !important;
    margin-left: 0px !important;
  }
  @media ${device.tablet} {
    width: 60% !important;
    margin-left: calc(50% - 232px) !important;
  }
  @media ${device.tabletPro} {
    width: 60% !important;
    margin-left: calc(50% - 251px) !important;
  }
  @media ${device.laptop} {
    width: 50% !important;
    margin-left: calc(50% - 256px) !important;
  }
  @media ${device.fullHDTV720p} {
    width: 40% !important;
    margin-left: calc(50% - 256px) !important;
  }
  @media ${device.laptopL} {
    width: 50% !important;
    margin-left: calc(50% - 320px) !important;
  }
`;

const CompanyChoices: React.FC<Props> = ({ setCompanyName, isTutorial }) => {
  const BagelAltText = "Bagel with cheese and salad greens inside";
  const [blurb, setBlurb] = React.useState<JSX.Element>(blockchainBagelsBlurb);
  const [title, setTitle] = React.useState("Blockchain Bagels");
  const [altText, setAltText] = React.useState(BagelAltText);

  const createCarouselItemImage = (company: string) => {
    return isTutorial && company !== BlockchainBagelsImage ? (
      <StyledImage data-testid={`${company}disabled-image`} key={company} src={company} style={{ opacity: 0.4 }} />
    ) : (
      <StyledImage data-testid={`${company}enabled-image`} key={company} src={company} alt={altText} />
    );
  };

  const companyChoices = (
    <StyledImage>
      {[BlockchainBagelsImage, NetworkedGnomesImage, ElephantEnterprisesImage].map(createCarouselItemImage)}
    </StyledImage>
  );

  const decideCompany = (index: number) => {
    // The selected company comes through based on an index number.
    switch (index) {
      case 0:
      default:
        setBlurb(blockchainBagelsBlurb);
        setTitle("Blockchain Bagels");
        setAltText(BagelAltText);
        setCompanyName(Company["Blockchain Bagels"]);
        break;
      case 1:
        setBlurb(networkedGnomesBlurb);
        setTitle("Networked Gnomes");
        setAltText("Gnome with a red hat and beard");
        setCompanyName(Company["Networked Gnomes"]);
        break;
      case 2:
        setBlurb(elephantEnterprisesBlurb);
        setTitle("Elephant Enterprises");
        setAltText("Face of an elephant");
        setCompanyName(Company["Elephant Enterprises"]);
        break;
    }
  };

  return (
    <>
      <CompanyCarousel
        showStatus={false}
        swipeable={true}
        showThumbs={false}
        showIndicators={true}
        onChange={selectedCompany => {
          decideCompany(selectedCompany);
        }}
        showArrows={true}
        centerMode={false}
      >
        {companyChoices.props.children}
      </CompanyCarousel>
      <BlurbContainer>
        <p data-testid="company-title" className="company-title">
          {title}
        </p>
        {blurb}
      </BlurbContainer>
    </>
  );
};

export default CompanyChoices;
