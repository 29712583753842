import { IMetrics } from "../../interfaces/metrics";

export const getAverageScore = (metrics: IMetrics) => {
  const sum = metrics.customerSatisfaction + metrics.people + metrics.productivity + metrics.stability;
  let average = Math.round(sum / 4);
  return average;
};

export const getScoreText = (metrics: IMetrics) => {
  let ScoreText = "";
  const scoreValue = getAverageScore(metrics);
  if (scoreValue <= 50) {
    ScoreText = "Low";
    return ScoreText;
  } else if (scoreValue > 50 && scoreValue < 80) {
    ScoreText = "Medium";
    return ScoreText;
  } else if (scoreValue >= 80 && scoreValue < 90) {
    ScoreText = "High";
    return ScoreText;
  } else if (scoreValue >= 90) {
    ScoreText = "Elite";
    return ScoreText;
  } else {
    return ScoreText;
  }
};
