import React from "react";
import { Input, Form } from "semantic-ui-react";
import NextButton from "../../shared/components/NextButton";
import { invalidName } from "../../shared/helpers/invalidName";
import styled from "styled-components";
import ReactGA from "react-ga4";
import { StyledContainer } from "../../shared/components/StyledContainer";

interface Props {
  playerName: string;
  setPlayerName: (playerName: string) => void;
  onDone: () => void;
}

const NameInput = styled.div`
  width: 100%;
  > p {
    font-weight: 600;
    margin-top: 10rem;
    display: flex;
  }
`;

const HintText = styled.div`
  > p {
    margin: 1rem;
    font-size: 12px;
    display: flex;
  }
`;

const EnterName = ({ playerName, setPlayerName, onDone }: Props) => {
  const [timeLoaded] = React.useState(new Date().getTime());

  React.useEffect(() => {
    return () => {
      ReactGA.event({
        category: "Name Entry duration",
        action: ((new Date().getTime() - timeLoaded) / 1000).toString(),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <Form>
        <NameInput>
          <p data-testid="yourNameText">First up, what should we call you?</p>
          <Input
            style={{ display: "initial", fontSize: "16px" }}
            data-testid="name-input"
            type="text"
            name="Name"
            onChange={event => setPlayerName(event.target.value.trim())}
          />
        </NameInput>
        <HintText data-testid="Hint-text">
          <p>Hint: At least one character please!</p>
        </HintText>
        <NextButton disabled={invalidName(playerName)} onClick={onDone} buttonIcon={true} />
      </Form>
    </StyledContainer>
  );
};

export default EnterName;
