export interface IEventsSelection {
  eventName: string;
  option: string;
}

interface IAddEvent {
  selectedEvents: IEventsSelection[];
  eventName: IEventsSelection["eventName"];
  option: IEventsSelection["option"];
}

export const addEvent = ({ eventName, option, selectedEvents }: IAddEvent) => {
  const playerEventList = selectedEvents;
  const playerEvent: IEventsSelection = {
    eventName: eventName,
    option: option,
  };

  playerEventList.push(playerEvent);
  return playerEventList;
};
