import React from "react";
import { IYearReport } from "../../interfaces/calendarReport";
import YearlyTrend from "./YearlyTrend";
import { Company } from "../../app";
import { Accordion, Icon } from "semantic-ui-react";

interface IYearlyTrendPanel {
  report: IYearReport;
  company: Company;
}

const YearlyTrendsPanel = ({ report, company }: IYearlyTrendPanel) => {
  const [activeIndex, setActiveState] = React.useState<number>();

  React.useEffect(() => {
    if (window.innerWidth > 400) {
      setActiveState(0);
    }
  }, []);

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveState(newIndex);
  };

  return (
    <Accordion
      fluid
      styled
      style={{
        marginBottom: "1rem",
        display: "block",
        boxShadow: "none",
      }}
      data-testid="trends-accordion"
    >
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
        style={{
          display: "flex",
          margin: window.innerWidth < 600 ? "0rem 0rem 1rem 1rem" : "1rem 5rem 1rem 0rem",
          color: "black",
        }}
      >
        <Icon name={activeIndex === 0 ? "chevron down" : "chevron right"} size="large" />
        Metrics Over 3 Years
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0} data-testid={"metric-content"}>
        <YearlyTrend report={report} company={company} />
      </Accordion.Content>
    </Accordion>
  );
};

export default YearlyTrendsPanel;
