import styled from "styled-components";
import React, { FC } from "react";
import NavBar from "./NavBar";
import NextButton from "./NextButton";

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;
const Navigation = styled.div`
  max-width: 100%;
`;

const Footer = styled.div`
  max-width: 100%;
  border-top: 1px solid #e0e0e0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
`;

const Body = styled.div`
  max-width: 100%;
`;

interface ILayoutProps {
  title?: string;
  confirmExit?: () => void;
  isTutorial?: boolean;
  testId?: string;
  disabled?: boolean;
  text?: string;
  onClick?: () => void;
  includeFooter?: boolean;
  includeNav?: boolean;
  icon?: string;
  buttonStyle?: React.CSSProperties;
  isInitiativeButton?: boolean;
  buttonIcon: boolean;
  // children is implicitly imported as part of FC
}

const PageLayout: FC<ILayoutProps> = ({
  children,
  title,
  confirmExit,
  isTutorial,
  testId,
  disabled,
  text,
  onClick,
  includeFooter,
  icon,
  includeNav,
  buttonStyle,
  isInitiativeButton,
  buttonIcon,
}) => {
  const buttonText = text || "Next";
  return (
    <Container>
      {includeNav && (
        <Navigation>
          <NavBar title={title} iconName={icon} confirmExit={confirmExit} isTutorial={isTutorial} />
        </Navigation>
      )}
      <Body
        style={{
          marginBottom: isInitiativeButton && text === "Confirm" ? "none" : "5rem",
        }}
      >
        {children}
      </Body>
      {includeFooter && (
        <Footer style={{ position: isInitiativeButton && text === "Confirm" ? "unset" : "fixed" }}>
          <NextButton
            testId={testId}
            disabled={disabled}
            text={buttonText}
            onClick={onClick}
            buttonStyle={buttonStyle}
            buttonIcon={buttonIcon}
          />
        </Footer>
      )}
    </Container>
  );
};

export default PageLayout;
