import * as React from "react";
import { Image } from "semantic-ui-react";
import styled from "styled-components";

const Banner = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px auto;
  padding: 1rem;
`;
interface IBanner {
  imageSrc: string;
  alt: string;
}
const BannerComponent = (props: IBanner) => {
  return (
    <Banner data-testid="company-banner">
      <Image src={props.imageSrc} alt={props.alt} />
    </Banner>
  );
};
export default BannerComponent;
