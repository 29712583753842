import { Event } from "../../interfaces";

export const pickRandomEventFromArray = (array: Event[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

// Borrowed from here: https://stackoverflow.com/a/6274381
export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
