import * as React from "react";
import Home from "./shared/pages/Home";
import GameSetup from "./gameMode/pages";
import { useState } from "react";
import Initiatives, { IInitiativeSelection } from "./initiatives/pages";
import { getCompanyBudget } from "./company/domain_model/getCompanyBudget";
import { Event, IMetrics, Initiative } from "./interfaces";
import Events from "./events/pages";
import Tutorial from "./gameMode/pages/tutorial";
import CompanySelect from "./company/pages";
import Metrics from "./gameEngine/pages";
import { IYearReport } from "./interfaces/calendarReport";
import { getStartingMetrics } from "./company/domain_model/getStartingMetrics";
import Score from "./score/pages";
import MetricsReport from "./gameEngine/pages/MetricsReport";
import { fireableMetrics } from "./shared/helpers/checkMetricsValue";
import { metricsVariableAndCost } from "./initiatives/domain_model/initiativesUpdateMetrics";
import produce from "immer";
import { getCurrentYear } from "./time/domain_model/getYearQuarter";
import { IEventsSelection } from "./events/domain_model/eventList";
import { getMetricImage } from "./gameEngine/domain_model/getMetricImage";
import { startingEvents } from "./events/domain_model";
import { shuffleInitiatives } from "./shared/helpers/shuffleInitiatives";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScorePage from "./score/sharepage/score";

interface IState {
  route:
    | "welcome"
    | "gameSetup"
    | "companySelect"
    | "initiatives"
    | "events"
    | "metrics"
    | "calendar"
    | "tutorial"
    | "score";
  gameState: GameConfig;
  initiativesState: IInitiativeSelection[];
  eventsState: IEventsSelection[];
  annualBudget: number;
  availableBudget: number;
  events: Event[];
  initiatives: Initiative[];
  metrics: IMetrics;
  page: string;
  calendarReport: IYearReport;
  budgetPercentage: number;
  initiativeFundLimit: number;
  exitTutorial: boolean;
}

export enum Company {
  "Blockchain Bagels" = "Blockchain Bagels",
  "Networked Gnomes" = "Networked Gnomes",
  "Elephant Enterprises" = "Elephant Enterprises",
}

const FF_INITIATIVES_FUNDING_LEVEL = Number(process.env.REACT_APP_FF_INITIATIVES_FUNDING_LEVEL) || 3;

export interface GameConfig {
  name: string;
  companyName: Company;
  tutorial: boolean;
  initialPage: string;
}

export const initialState = (): IState => ({
  route: "welcome",
  gameState: { name: "", companyName: Company["Blockchain Bagels"], tutorial: false, initialPage: "enterName" },
  initiativesState: [],
  eventsState: [],
  annualBudget: 0,
  availableBudget: 0,
  events: [],
  initiatives: [],
  budgetPercentage: 100,
  initiativeFundLimit: FF_INITIATIVES_FUNDING_LEVEL,
  metrics: getStartingMetrics(),
  page: "",
  exitTutorial: false,
  calendarReport: {
    currentYear: 1,
    currentQuarter: 0,
    calendarYearsInfo: [
      {
        count: 1,
        quarters: [
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
        ],
      },
      {
        count: 2,
        quarters: [
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
        ],
      },
      {
        count: 3,
        quarters: [
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
          { quarter: false, metrics: { people: 0, customerSatisfaction: 0, productivity: 0, stability: 0 } },
        ],
      },
    ],
  },
});

export default function App() {
  const [appState, setAppState] = useState<IState>(initialState());

  const onTutorialWhatsInitiatives = () => {
    setAppState(it => ({
      ...it,
      route: "initiatives",
      page: "selectInitiative",
      annualBudget: getCompanyBudget(it.gameState.companyName),
    }));
  };

  const companySet = () => {
    // Before the company is chosen, reset the budgets to zero, then make changes based on company decisions.
    setAppState(state => ({
      ...state,
      annualBudget: 0,
      availableBudget: 0,
      budgetPercentage: 100,
    }));
    appState.gameState.tutorial
      ? setAppState(state => ({
          ...state,
          route: "tutorial",
          page: "whatsAnInitiative",
          annualBudget: getCompanyBudget(state.gameState.companyName),
        }))
      : setAppState(state => ({
          ...state,
          route: "metrics",
          page: "QuarterlyReport",
          annualBudget: getCompanyBudget(state.gameState.companyName),
          metrics: getStartingMetrics(state.gameState.companyName),
        }));
  };

  const gameStart = () => {
    setAppState(it => ({
      ...it,
      route: "companySelect",
      events: startingEvents(it.gameState.tutorial),
      initiatives: shuffleInitiatives(it.gameState.tutorial),
      exitTutorial: false,
    }));
  };

  React.useEffect(
    function updateQuarterMetrics() {
      const newQuarterMetrics = produce(appState.calendarReport, draft => {
        getCurrentYear(draft, appState.metrics).quarters[appState.calendarReport.currentQuarter - 1] = {
          quarter: true,
          metrics: appState.metrics,
        };
      });
      setAppState(it => ({
        ...it,
        calendarReport: newQuarterMetrics,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.metrics],
  );

  React.useEffect(
    function shouldPlayerBeFired() {
      if (appState.gameState.tutorial) return;
      if (fireableMetrics(appState.metrics)) {
        setAppState(it => ({
          ...it,
          route: "gameSetup",
          events: [],
          gameState: { ...it.gameState, initialPage: "firePlayer" },
          calendarReport: initialState().calendarReport,
          initiativesState: [],
          page: "firePlayer",
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.metrics, appState.initiativesState.length, appState.page],
  );

  const initiativesSet = (
    initiativesState: IInitiativeSelection[],
    availableBudget: number,
    budgetPercentage: number,
  ) => {
    setAppState({
      ...appState,
      initiativesState,
      metrics: metricsVariableAndCost(initiativesState, appState.metrics),
      availableBudget: availableBudget,
      budgetPercentage: budgetPercentage,
    });

    if (appState.gameState.tutorial) {
      setAppState(it => ({
        ...it,
        route: "metrics",
        page: "WhatAreMetrics",
      }));
    } else {
      setAppState(it => ({
        ...it,
        route: "events",
        page: "events",
      }));
    }
  };

  const eventSet = React.useCallback(
    (events: Event[], metrics: IMetrics, eventsState: IEventsSelection[]) => {
      setAppState(it => ({ ...it, eventsState: eventsState }));
      if (appState.route === "events") {
        if (appState.gameState.tutorial) {
          setAppState(it => ({ ...it, route: "tutorial", page: "whatHappened" }));
        } else if (appState.page === "events") {
          setAppState(it => ({ ...it, route: "events", page: "", events: events, metrics: metrics }));
        } else if (appState.page === "") {
          setAppState(it => ({ ...it, route: "metrics", page: "QuarterlyReport", events: events, metrics: metrics }));
        }
      }
    },
    [appState.page, appState.gameState.tutorial, appState.route],
  );

  React.useEffect(
    function updateEventList() {
      if (appState.gameState.tutorial) return;
      if (fireableMetrics(appState.metrics)) return;
      if (appState.route === "events" && appState.page === "") {
        setAppState(it => ({ ...it, route: "metrics", page: "QuarterlyReport" }));
      }
    },
    [appState.page, appState.route, appState.gameState.tutorial, appState.metrics],
  );

  React.useEffect(
    () => {
      if (appState.events.length === 0) return;
      if (appState.route === "companySelect") return;

      const stepper = 1;
      if (appState.gameState.tutorial) {
        if (appState.exitTutorial) {
          setAppState(it => ({
            ...it,
            route: "gameSetup",
            page: "tutorialSelect",
          }));
        } else {
          setAppState(it => ({
            ...it,
            route: "events",
            page: "events",
          }));
        }
      } else if (appState.calendarReport.currentQuarter === 0 && appState.calendarReport.currentYear === 3) {
        setAppState(it => ({
          ...it,
          route: "score",
          page: "MyScore",
        }));
      } else {
        if (appState.calendarReport.currentQuarter === stepper) {
          setAppState(it => ({ ...it, route: "initiatives" }));
        } else {
          setAppState(it => ({
            ...it,
            route: "events",
            page: "events",
          }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.calendarReport.currentQuarter],
  );

  const onExitTutorial = () => {
    setAppState(it => ({
      ...it,
      gameState: { ...it.gameState, initialPage: "tutorialSelect", companyName: Company["Blockchain Bagels"] },
      event: [],
      initiativesState: [],
      metrics: getStartingMetrics(),
      calendarReport: initialState().calendarReport,
      route: "gameSetup",
      exitTutorial: true,
    }));
  };

  const onExitGame = () => {
    setAppState(it => ({
      ...it,
      gameState: { ...it.gameState, initialPage: "jobOffer", companyName: Company["Blockchain Bagels"] },
      initiativesState: [],
      metrics: getStartingMetrics(),
      calendarReport: initialState().calendarReport,
      route: "companySelect",
      initiatives: shuffleInitiatives(true),
    }));
  };

  const getImage = React.useCallback(
    metric => {
      return getMetricImage(metric, appState.gameState.companyName, appState.calendarReport, appState.route);
    },
    [appState.gameState.companyName, appState.calendarReport, appState.route],
  );

  const RenderScreen = () => {
    switch (appState.route) {
      case "welcome":
        return <Home onDone={() => setAppState({ ...appState, route: "gameSetup" })} />;
      case "gameSetup":
        return (
          <GameSetup
            playerName={appState.gameState.name}
            companyName={appState.gameState.companyName}
            state={appState.gameState}
            updateGameState={gameState => setAppState({ ...appState, gameState })}
            onSetupCompleted={() => gameStart()}
            metrics={appState.metrics}
            initiativeState={appState.initiativesState}
            eventState={appState.eventsState}
          />
        );
      case "companySelect":
        return (
          <CompanySelect
            state={appState.gameState}
            updateGameState={gameState => setAppState({ ...appState, gameState })}
            onSetupCompleted={() => companySet()}
            onExitTutorial={() => onExitTutorial()}
            page={appState.page}
          />
        );
      case "initiatives":
        return (
          <Initiatives
            totalBudget={appState.annualBudget}
            initiatives={appState.initiatives}
            selectedInitiatives={appState.initiativesState}
            tutorial={appState.gameState.tutorial}
            onExitTutorial={() => onExitTutorial()}
            onComplete={(it, availableBudget, budgetPercentage) => {
              initiativesSet(it, availableBudget, budgetPercentage);
            }}
            currentYear={appState.calendarReport.currentYear}
            budgetPercentage={appState.budgetPercentage}
            initiativeFundLimit={appState.initiativeFundLimit}
          />
        );
      case "metrics":
        return (
          <Metrics
            companyName={appState.gameState.companyName}
            playerName={appState.gameState.name}
            metrics={appState.metrics}
            onDone={() => setAppState({ ...appState, route: "calendar" })}
            page={appState.page}
            tutorial={appState.gameState.tutorial}
            onExitTutorial={() => onExitTutorial()}
            calendarReport={appState.calendarReport}
            updateCalendarReport={calendarReport => setAppState({ ...appState, calendarReport })}
            getArrowImage={(metric: keyof IMetrics) => {
              return getImage(metric);
            }}
          />
        );
      case "events":
        return (
          <Events
            events={appState.events}
            metrics={appState.metrics}
            selectedEvents={appState.eventsState}
            eventSet={(events: Event[], metrics: IMetrics, eventsState: IEventsSelection[]) => {
              eventSet(events, metrics, eventsState);
            }}
            onExitTutorial={() => onExitTutorial()}
            isTutorial={appState.gameState.tutorial}
          />
        );
      case "calendar":
        return (
          <>
            <MetricsReport
              companyName={appState.gameState.companyName}
              playerName={appState.gameState.name}
              getArrowImage={(metric: keyof IMetrics) => {
                return getImage(metric);
              }}
              metrics={appState.metrics}
              timelineReport={appState.calendarReport}
              updateTimeline={calendarReport => setAppState({ ...appState, calendarReport })}
              onExitTutorial={() => onExitTutorial()}
            />
          </>
        );
      case "tutorial":
        return (
          <Tutorial
            metrics={appState.metrics}
            onExitTutorial={() => onExitTutorial()}
            isTutorial={appState.gameState.tutorial}
            name={appState.gameState.name}
            companyName={appState.gameState.companyName}
            updateGameState={(setTutorial: boolean) =>
              setAppState(it => ({
                ...it,
                gameState: { ...it.gameState, tutorial: setTutorial },
                // FIXME - DRY
                events: startingEvents(setTutorial),
              }))
            }
            onExitGame={() => onExitGame()}
            onTutorialWhatsInitiatives={onTutorialWhatsInitiatives}
            pageToGo={appState.page}
          />
        );
      case "score":
        return (
          <Score
            page={appState.page}
            initiativesState={appState.initiativesState}
            eventState={appState.eventsState}
            name={appState.gameState.name}
            metrics={appState.metrics}
            onDone={onExitGame}
            report={appState.calendarReport}
            getArrowImage={(metric: keyof IMetrics) => {
              return getImage(metric);
            }}
            company={appState.gameState.companyName}
          />
        );
      default:
        return <h1>Something has gone gravely wrong, and you're not meant to be here.</h1>;
    }
  };

  return (
    <Router>
      <Switch>
        <Route path="/scoreV3/:id">
          <ScorePage />
        </Route>
        <Route path="/">{RenderScreen()}</Route>
      </Switch>
    </Router>
  );
}
