import * as React from "react";
import MyScore from "./myScore";
import { IMetrics } from "../../interfaces";
import { IYearReport } from "../../interfaces/calendarReport";
import { IInitiativeSelection } from "../../initiatives/pages";
import { IEventsSelection } from "../../events/domain_model/eventList";
import { Company } from "../../app";

interface Props {
  page: string;
  metrics: IMetrics;
  onDone: () => void;
  report: IYearReport;
  initiativesState: IInitiativeSelection[];
  eventState: IEventsSelection[];
  name: string;
  company: Company;
  getArrowImage: (metric: keyof IMetrics) => string;
}

const Score: React.FC<Props> = ({
  page,
  metrics,
  onDone,
  report,
  initiativesState,
  name,
  eventState,
  company,

  getArrowImage,
}) => {
  switch (page) {
    case "MyScore":
      return (
        <MyScore
          metrics={metrics}
          onDone={onDone}
          report={report}
          name={name}
          initiativesState={initiativesState}
          eventState={eventState}
          getArrowImage={getArrowImage}
          company={company}
          page={page}
        />
      );

    default:
      return <h1>Ooops. You're not meant to be here.</h1>;
  }
};

export default Score;
