import React from "react";
import styled from "styled-components";
import Timeline from "./Timeline";
import { Container } from "semantic-ui-react";
import { IYearReport } from "../../interfaces/calendarReport";

const Year = styled.div`
  font-family: Mukta;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #006b92;
`;

interface IYearlyProps {
  timelines: IYearReport;
}

const TimelineContainer = styled(Container)`
  width: 500px !important;
`;

const YearlyTimelines = ({ timelines }: IYearlyProps) => {
  return (
    <div style={{ backgroundColor: "#F2F2F2" }}>
      {timelines.calendarYearsInfo.map((yearlyTimeline, index) => {
        return (
          timelines.currentYear === yearlyTimeline.count && (
            <TimelineContainer data-testid="yearly-timelines" key={index}>
              <Year data-testid={`yearLabel-${yearlyTimeline.count}`}>Year {yearlyTimeline.count}</Year>
              <Timeline quarters={yearlyTimeline.quarters} key={yearlyTimeline.count} />
            </TimelineContainer>
          )
        );
      })}
    </div>
  );
};

export default YearlyTimelines;
