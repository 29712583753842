import * as React from "react";
import Gnome from "../../assets/images/jobOffer/gnome_offer.svg";
import Elephant from "../../assets/images/jobOffer/elephant_offer.svg";
import Bagel from "../../assets/images/jobOffer/bagel_offer.svg";
import ImageLayout from "../../shared/components/ImageLayout";
import { StyledContainer } from "../../shared/components/StyledContainer";
import { Container } from "semantic-ui-react";
import NextButton from "../../shared/components/NextButton";
import { device } from "../../shared/helpers/deviceConfig";
import styled from "styled-components";

const ConditionalFormatting = styled.div`
  @media ${device.tablet} {
    margin: 7em;
  }
`;

const JobOfferContainer = styled(StyledContainer)`
  @media ${device.desktopL} {
    width: 500px;
    margin-left: calc(50% - 500px / 2) !important;
  }
`;

interface JobOfferProps {
  playerName: string;
  onDone: () => void;
  onClose: () => void;
  isTutorial: boolean;
}

const JobOffer: React.FC<JobOfferProps> = ({ playerName, onDone, onClose, isTutorial }) => {
  return (
    <ImageLayout
      title={isTutorial ? "Tutorial Start" : ""}
      icon="close"
      confirmExit={onClose}
      isTutorial={isTutorial}
      includeNav={isTutorial}
      GnomeSource={Gnome}
      BagelSource={Bagel}
      ElephantSource={Elephant}
    >
      <JobOfferContainer>
        <ConditionalFormatting />
        <h1 style={{ overflowWrap: "break-word" }} data-testid="welcomeNameText">
          Hi {playerName},
        </h1>
        <p>
          Because of the impressive work you undertook at your last position, THREE different companies have offered you
          a job as their Chief Information Officer — which will you accept?
        </p>
        <Container style={{ margin: "7em" }}>
          <NextButton disabled={false} onClick={onDone} buttonIcon={true} />
        </Container>
      </JobOfferContainer>
    </ImageLayout>
  );
};

export default JobOffer;
