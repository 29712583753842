import React from "react";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { device } from "../helpers/deviceConfig";

interface Props {
  testId?: string;
  disabled?: boolean;
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  buttonStyle?: React.CSSProperties;
  buttonIcon: boolean;
}

const StyledButton = styled.div`
  @media ${device.desktopL} {
    width: 355px !important;
    height: 50px;
    margin-left: 25%;
  }
  @media ${device.desktop} {
    width: 355px !important;
    height: 50px;
    margin-left: 25%;
  }
  @media ${device.laptopL} {
    width: 355px !important;
    height: 50px;
    margin-left: 25%;
  }
  @media ${device.laptop} {
    width: 355px !important;
    height: 50px;
    margin: auto;
  }
  @media ${device.tablet} {
    width: 355px !important;
    height: 50px;
    margin: auto;
  }
  @media ${device.mobileL} {
    width: 90%;
    margin: auto;
  }
  @media ${device.mobileM} {
    width: 90%;
    margin: auto;
  }
  @media ${device.mobileS} {
    width: 90%;
    margin: auto;
  }
`;

const ButtonFocus = styled(Button)`
  :focus {
    border: 2px solid #27c6ff !important;
    transition: border 0.2s;
  }
`;

function NextButton(props: Props) {
  const defaultStyle = () => {
    if (props.disabled) {
      return {
        margin: "1rem auto",
        background: "white",
        color: "black",
        maxWidth: "530px",
        borderRadius: "10px",
        border: "2px solid #E5E5E5",
      };
    } else {
      return {
        margin: "1rem auto",
        background: "#006B92",
        color: "#FFFFFF",
        maxWidth: "530px",
        borderRadius: "10px",
        border: "2px solid #006B92",
      };
    }
  };

  return (
    <StyledButton>
      <ButtonFocus
        data-testid={props.testId || "nextbtn"}
        fluid
        size="large"
        tabIndex={0}
        style={props.buttonStyle || defaultStyle()}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.text ? props.text : "Next"}
        {props.buttonIcon && <Icon name="chevron right" />}
      </ButtonFocus>
    </StyledButton>
  );
}

export default NextButton;
