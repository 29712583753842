import React from "react";
import styled from "styled-components";
import speechBubble from "../../assets/images/metrics_speechBubble.svg";
import { Image } from "semantic-ui-react";
import bagel from "../../assets/images/bagel_with_money.svg";
import { device } from "../../shared/helpers/deviceConfig";

const Speech = styled.div`
  height: 150px;
  background-image: url(${speechBubble});
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;

  @media ${device.mobileS} {
    margin: 2rem 1rem 0;
    height: 128px;
  }
  @media ${device.mobileM} {
    margin: 2rem 2rem 0;
    height: 140px;
  }
  @media ${device.mobileL} {
    margin: 3rem 3rem 0;
    height: 149px;
  }
  @media ${device.tablet} {
    margin: 3rem 7rem 0 3rem;
  }
  @media ${device.laptop} {
    margin: 3rem 10rem 0 3rem;
  }
  @media ${device.laptopL} {
    margin: 3rem 9rem 0 3rem;
  }
  @media ${device.desktop} {
    margin: 3rem 18rem 0 3rem;
  }
  @media ${device.desktopL} {
    margin: 3rem 33rem 0 3rem;
  }
`;
const LogoContainer = styled.div`
  display: grid;
  justify-content: flex-end;
  grid-template-columns: repeat(2, 1fr);
  justify-items: start;
  align-content: flex-start;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  margin-bottom: 0;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 4rem;
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 4rem;
  }
  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 4rem;
  }
  @media ${device.desktop} {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 4rem;
  }
  @media ${device.desktopL} {
    margin-bottom: 4rem;
    grid-template-columns: repeat(3, 1fr);
    justify-self: center;
  }
`;
const SpeechLogo = styled(Image)`
  justify-self: center;
  align-self: center;
  grid-row: 1;
  grid-column: 2;
  padding: 0;
  height: 128px;

  @media ${device.mobileS} {
    margin-left: 62px;
    justify-self: center;
  }
  @media ${device.mobileM} {
    margin-left: 62px;
    justify-self: center;
  }
  @media ${device.mobileL} {
    margin-left: 0;
    height: 128px;
    grid-template-columns: repeat(3, 1fr);
    justify-self: flex-end;
  }

  @media ${device.tablet} {
    margin-left: 0;
    height: 128px;
    grid-template-columns: repeat(3, 1fr);
    justify-self: flex-start;
  }
  @media ${device.laptop} {
    margin-left: 0;
    height: 128px;
    grid-template-columns: repeat(3, 1fr);
    justify-self: flex-start;
  }
  @media ${device.laptopL} {
    margin-left: 0;
    height: 128px;
    grid-template-columns: repeat(3, 1fr);
    justify-self: flex-start;
    }
  }
  @media ${device.desktop} {
    margin-left: 0;
    height: 128px;
    grid-template-columns: repeat(3, 1fr);
    justify-self: flex-start;
  }
  @media ${device.desktopL} {
    margin-left: 0;
    height: 128px;
    grid-template-columns: repeat(3, 1fr);
    justify-self: flex-end;
    margin-right: 7rem;
    }
  }
`;
const IntroText = styled.div`
  > p {
    height: 61px;
    position: absolute;
  }

  @media ${device.mobileS} {
    > p {
      max-width: 165px;
      left: 11px;
      top: 261px;
    }
  }
  @media ${device.galaxyS} {
    > p {
      max-width: 165px;
      left: 29px;
      top: 261px;
    }
  }

  @media ${device.mobileM} {
    > p {
      max-width: 165px;
      left: 39px;
      top: 280px;
    }
  }
  @media ${device.mobileL} {
    > p {
      max-width: 200px;
      left: 61px;
      top: 303px;
    }
  }
  @media ${device.tablet} {
    > p {
      max-width: 400px;
      left: 136px;
      top: 370px;
    }
  }
  @media ${device.tabletPro} {
    > p {
      max-width: 400px;
      left: 167px;
      top: 370px;
    }
  }

  @media ${device.laptop} {
    > p {
      max-width: 400px;
      left: 239px;
      top: 370px;
    }
  }

  @media ${device.fullHDTV720p} {
    > p {
      max-width: 400px;
      left: 365px;
      top: 370px;
    }
  }
  @media ${device.laptopL} {
    > p {
      max-width: 400px;
      left: 443px;
      top: 370px;
    }
  }

  @media ${device.fullHDTV1080p} {
    > p {
      max-width: 400px;
      left: 610px;
      top: 370px;
    }
  }
  @media ${device.desktop} {
    > p {
      max-width: 400px;
      left: 926px;
      top: 370px;
    }
  }
  @media ${device.desktopL} {
    > p {
      max-width: 400px;
      left: 1420px;
      top: 370px;
    }
  }
`;

const MetricsSpeech = () => {
  return (
    <>
      <Speech data-testid="what-are-metrics" />
      <LogoContainer data-testid="rightSide-logo">
        <SpeechLogo src={bagel} alt="Bagel with cheese and salad greens inside" />
        <IntroText>
          <p>
            Now let’s look at our <strong>Performance Metrics.</strong>
          </p>
        </IntroText>
      </LogoContainer>
    </>
  );
};

export default MetricsSpeech;
