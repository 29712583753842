import { shuffleArray } from "../../shared/helpers/arrayTools";
import { Initiative } from "../../interfaces";

export const originalList: Initiative[] = require("../../initiatives/domain_model/initiatives.json");

export const shuffleInitiatives = (isTutorial: boolean) => {
  if (isTutorial) {
    return originalList;
  }
  return shuffleArray([...originalList]);
};
