import { IMetrics } from "../../../interfaces/metrics";
import { nodes } from "../../../interfaces/nodes";

type MetricsOperator = (metrics: IMetrics) => IMetrics;

const combine = (...operators: MetricsOperator[]): MetricsOperator => metrics => {
  let result = metrics;
  for (const operator of operators) {
    result = operator(result);
  }
  return result;
};
const NoOpVisitor: EngineVisitor = {
  onNode(name: string) {},
};
export interface EngineVisitor {
  onNode: (name: string) => void;
}
export const calculateMetrics = (
  node: nodes,
  modifier: number,
  engineVisitor: EngineVisitor = NoOpVisitor,
): MetricsOperator => {
  const visit = (node: nodes): void => {
    engineVisitor.onNode(node);
  };

  const automatedTesting = (modifier: number): MetricsOperator => {
    visit("automatedTesting");
    return continuousIntegration(modifier);
  };
  const branchingStrategy = (modifier: number): MetricsOperator => {
    visit("branchingStrategy");
    return continuousDelivery(modifier);
  };
  const burnout = (modifier: number): MetricsOperator => {
    visit("burnout");
    return people(modifier * -1);
  };
  const cloudMigration = (modifier: number): MetricsOperator => {
    visit("cloudMigration");
    return combine(productivity(modifier), stability(modifier));
  };
  const codeMaintainability = (modifier: number): MetricsOperator => {
    visit("codeMaintainability");
    return combine(technicalDebt(modifier * -1), continuousDelivery(modifier));
  };
  const cognitiveBurden = (modifier: number): MetricsOperator => {
    visit("cognitiveBurden");
    return burnout(modifier);
  };
  const continuousDelivery = (modifier: number): MetricsOperator => {
    visit("continuousDelivery");
    return combine(productivity(modifier), reliability(modifier));
  };
  const continuousIntegration = (modifier: number): MetricsOperator => {
    visit("continuousIntegration");
    return continuousDelivery(modifier);
  };
  const cultureOfPsychologicalSafety = (modifier: number): MetricsOperator => {
    visit("cultureOfPsychologicalSafety");
    return combine(people(modifier), productivity(modifier));
  };
  const deploymentAutomation = (modifier: number): MetricsOperator => {
    visit("deploymentAutomation");
    return continuousDelivery(modifier);
  };
  const easyToUseTools = (modifier: number): MetricsOperator => {
    visit("easyToUseTools");
    return softwareDevelopmentCapability(modifier);
  };
  const feedbackLoops = (modifier: number): MetricsOperator => {
    visit("feedbackLoops");
    return softwareDevelopmentCapability(modifier);
  };
  const looselyCoupledArchitecture = (modifier: number): MetricsOperator => {
    visit("looselyCoupledArchitecture");
    return combine(technicalDebt(modifier * -1), continuousDelivery(modifier));
  };
  const monitoring = (modifier: number): MetricsOperator => {
    visit("monitoring");
    return combine(technicalDebt(modifier * -1), continuousDelivery(modifier));
  };
  const reliability = (modifier: number): MetricsOperator => {
    visit("reliability");
    return stability(modifier);
  };
  const simplicityOfChangeControl = (modifier: number): MetricsOperator => {
    visit("simplicityOfChangeControl");
    return combine(cultureOfPsychologicalSafety(modifier), productivity(modifier));
  };
  const softwareDevelopmentCapability = (modifier: number): MetricsOperator => {
    visit("softwareDevelopmentCapability");
    return productivity(modifier);
  };
  const technicalDebt = (modifier: number): MetricsOperator => {
    visit("technicalDebt");
    return combine(cognitiveBurden(modifier), productivity(modifier * -1));
  };

  // Below are the sinks
  const customerSatisfaction = (modifier: number): MetricsOperator => metrics => {
    visit("customerSatisfaction");
    return {
      ...metrics,
      customerSatisfaction: metrics.customerSatisfaction + modifier,
    };
  };
  const people = (modifier: number): MetricsOperator => metrics => {
    visit("people");
    return {
      ...metrics,
      people: metrics.people + modifier,
    };
  };
  const productivityInternal = (modifier: number): MetricsOperator => metrics => ({
    ...metrics,
    productivity: metrics.productivity + modifier,
  });
  const productivity = (modifier: number): MetricsOperator => {
    visit("productivity");
    return combine(
      productivityInternal(modifier),
      people(modifier),
      customerSatisfaction(modifier),
      burnout(modifier * -1),
    );
  };
  const stabilityInternal = (modifier: number): MetricsOperator => metrics => ({
    ...metrics,
    stability: metrics.stability + modifier,
  });
  const stability = (modifier: number): MetricsOperator => {
    visit("stability");
    return combine(stabilityInternal(modifier), customerSatisfaction(modifier));
  };

  switch (node) {
    case "automatedTesting":
      return automatedTesting(modifier);
    case "branchingStrategy":
      return branchingStrategy(modifier);
    case "burnout":
      return burnout(modifier);
    case "cloudMigration":
      return cloudMigration(modifier);
    case "codeMaintainability":
      return codeMaintainability(modifier);
    case "cognitiveBurden":
      return cognitiveBurden(modifier);
    case "continuousDelivery":
      return continuousDelivery(modifier);
    case "continuousIntegration":
      return continuousIntegration(modifier);
    case "cultureOfPsychologicalSafety":
      return cultureOfPsychologicalSafety(modifier);
    case "deploymentAutomation":
      return deploymentAutomation(modifier);
    case "easyToUseTools":
      return easyToUseTools(modifier);
    case "feedbackLoops":
      return feedbackLoops(modifier);
    case "looselyCoupledArchitecture":
      return looselyCoupledArchitecture(modifier);
    case "monitoring":
      return monitoring(modifier);
    case "reliability":
      return reliability(modifier);
    case "simplicityOfChangeControl":
      return simplicityOfChangeControl(modifier);
    case "softwareDevelopmentCapability":
      return softwareDevelopmentCapability(modifier);
    case "technicalDebt":
      return technicalDebt(modifier);
    case "customerSatisfaction":
      return customerSatisfaction(modifier);
    case "people":
      return people(modifier);
    case "productivity":
      return productivity(modifier);
    case "stability":
      return stability(modifier);
    default:
      throw new Error("Unknown node modifier: " + node);
  }
};
