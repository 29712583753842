import styled from "styled-components";
import { device } from "../helpers/deviceConfig";

export const StyledContainer = styled.div`
  text-align: left;

  @media ${device.mobileS} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }
  @media ${device.mobileM} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }
  @media ${device.mobileL} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }
  @media ${device.tablet} {
    max-width: 500px;
    margin-left: calc(50% - 500px / 2);

  }
  @media ${device.tabletPro} {
    max-width: 500px;
    margin-left: calc(50% - 500px / 2);

  }
  @media ${device.tabletPro} {
    max-width: 500px;
    margin-left: calc(50% - 500px / 2);
  }
  @media ${device.laptop} {
    max-width: 550px;
    margin-left: calc(50% - 550px / 2);
   ]
  }
  @media ${device.fullHDTV720p} {
    max-width: 550px;
    margin-left: calc(50% - 550px / 2);

  }
  @media ${device.fullHDTV720p} {
    max-width: 550px;
    margin-left: calc(50% - 550px / 2);
  }
  @media ${device.laptopL} {
    max-width: 550px;
    margin-left: calc(50% - 550px / 2);

  }
  @media ${device.fullHDTV1080p} {
    max-width: 700px;
    margin-left: calc(50% - 700px / 2);

  }
  @media ${device.fullHDTV1080p} {
    max-width: 700px;
    margin-left: calc(50% - 700px / 2);
  }
  @media ${device.desktop} {
    max-width: 700px;
    margin-left: calc(50% - 700px / 2);

  }
  @media ${device.desktopL} {
    max-width: 1000px;
    margin-left: calc(50% - 1000px / 2);

  }
`;
