import { Helmet } from "react-helmet";
import React, { useEffect } from "react";

interface prop {
  imageURL: string;
  imageId: string;
  domainUrl: string;
}
const HelmetTags = ({ imageURL, imageId, domainUrl }: prop) => {
  /**
   * Meta tags are defined in the index.html file for sharing the root of the site.
   * These tags are not overridden by Helmet, meaning the page contains duplicate tags.
   * Duplicate tags are invalid and lead to undefined behaviour
   * Therefore remove all the tags that come from the index.html
   * @see public/index.html
   */
  useEffect(() => {
    let ogMetaTags = document.querySelectorAll('meta[property^="og:"]');
    let twitterMetaTags = document.querySelectorAll('meta[name^="twitter:"]');
    const removeNonManagedTags = (tag: any) => {
      if (!tag.dataset) throw new Error("Helmet: html.dataset is not available in this browser.");
      else if (!tag.dataset.reactHelmet) tag.parentNode.removeChild(tag);
    };
    twitterMetaTags.forEach(removeNonManagedTags);
    ogMetaTags.forEach(removeNonManagedTags);
  });

  return (
    <Helmet>
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@https://twitter.com/DevOps_Dream" />
      <meta name="twitter:title" content="DevOps Dream" />
      <meta
        name="twitter:description"
        content="A game for modern tech leaders to improve software delivery and organisational performance, based on real industry insights."
      />
      <meta name="twitter:image" content={imageURL} />
      {/* OpenGraph tags */}
      <meta property="og:url" content={`${domainUrl}/scoreV3/${imageId}/index.html`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="DevOps Dream" />
      <meta
        property="og:description"
        content="A game for modern tech leaders to improve software delivery and organisational performance, based on real industry insights."
      />
      <meta property="og:image" content={imageURL} />
    </Helmet>
  );
};

export default HelmetTags;
