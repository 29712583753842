import React from "react";
import { Grid } from "semantic-ui-react";
import { getScoreText, getAverageScore } from "../domain_model/getScore";
import { IMetrics } from "../../interfaces";
import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";

interface ScoreProps {
  metrics: IMetrics;
}

const ScorePerformanceContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0 1rem 0;
`;

const LeftCardContainer = styled(Grid.Column)`
  background: #f2f2f2;
  border-radius: 10px;
  margin: 1rem 0.5rem 1rem 1rem;
`;
const RightCardContainer = styled(Grid.Column)`
  margin: 1rem 1rem 1rem 0.5rem;
  background: #f2f2f2;
  border-radius: 10px;
`;
const CardText = styled(Grid.Row)`
  font-family: Mukta;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #333333;
`;
const ScorePerformanceText = styled(Grid.Row)`
  font-family: Mukta;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #333333;
  margin-bottom: 1rem;

  @media ${device.mobileS} {
    font-size: 32px;
  }
  @media ${device.tablet} {
    font-size: 40px;
  }
`;

const ScorePerformancePanel = ({ metrics }: ScoreProps) => {
  return (
    <ScorePerformanceContainer>
      <Grid columns="equal">
        <LeftCardContainer>
          <Grid.Column width={5} style={{ padding: "1rem 0" }}>
            <CardText>
              <Grid.Row style={{ marginBottom: "2rem" }}>Your DevOpsDream Performance:</Grid.Row>
            </CardText>
            <ScorePerformanceText>
              <Grid.Row data-testid="score-performance">{getScoreText(metrics)}</Grid.Row>
            </ScorePerformanceText>
          </Grid.Column>
        </LeftCardContainer>
        <RightCardContainer>
          <Grid.Column width={5} style={{ padding: "1rem 0" }}>
            <CardText>
              <Grid.Row
                data-testid="my-score"
                style={{ marginBottom: `${window.innerWidth < 370 ? "4.5rem" : "3rem"}` }}
              >
                Your Final Score:
              </Grid.Row>
            </CardText>
            <ScorePerformanceText>
              <Grid.Row data-testid="average-score">{getAverageScore(metrics)}%</Grid.Row>
            </ScorePerformanceText>
          </Grid.Column>
        </RightCardContainer>
      </Grid>
    </ScorePerformanceContainer>
  );
};

export default ScorePerformancePanel;
