import React from "react";
import { IMetrics } from "../../interfaces/metrics";
import { Container, Image } from "semantic-ui-react";
import styled from "styled-components";
import { IYearReport } from "../../interfaces/calendarReport";
import { getCurrentYear } from "../../time/domain_model/getYearQuarter";
import { produce } from "immer";
import MetricsGrid from "../../score/components/MetricsGrid";
import YearlyTimelines from "../../time/components/YearlyTimelines";
import { Company } from "../../app";
import { GenerateID } from "../../shared/helpers/testIdGenerator";
import BagelCompanyImage from "../../assets/images/BagelCompanyImage.svg";
import ElephantCompanyImage from "../../assets/images/ElephantCompanyImage.svg";
import GnomeCompanyImage from "../../assets/images/GnomeCompanyImage.svg";
import YearlyTrendsPanel from "../components/YearlyTrendsPanel";
import PageTemplate from "../../shared/components/PageTemplate2";
import WideNavigation from "../../shared/components/WideNavigation";
import { device } from "../../shared/helpers/deviceConfig";

interface Props {
  companyName: Company;
  playerName: string;
  metrics: IMetrics;
  timelineReport: IYearReport;
  updateTimeline: (newTimeline: IYearReport) => void;
  onExitTutorial?: () => void;
  getArrowImage: (metric: keyof IMetrics) => string;
}

const CompanyImage = styled(Image)`
  margin: 0;
  padding: 0;
  left: 140px;
`;

const ContentContainer = styled.div`
  @media ${device.mobileS} {
    display: grid;
    justify-content: center;
    margin: auto 2rem;
  }
  @media ${device.mobileM} {
    display: grid;
    justify-content: center;
    margin: auto 2rem;
  }
  @media ${device.mobileL} {
    display: grid;
    justify-content: center;
    margin: auto 2rem;
  }
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }
`;

const MetricsReport: React.FC<Props> = ({
  companyName,
  playerName,
  metrics,
  timelineReport,
  updateTimeline,
  onExitTutorial,
  getArrowImage,
}) => {
  const stepper = 4;
  const fullGameMode: boolean = onExitTutorial === undefined;
  const [companyImage, setCompanyImage] = React.useState("");
  const [altText, setAltText] = React.useState("");
  React.useEffect(() => {
    switch (companyName) {
      case Company["Blockchain Bagels"]:
        setAltText("Bagel with cheese and salad greens inside");
        setCompanyImage(BagelCompanyImage);
        break;
      case Company["Networked Gnomes"]:
        setAltText("Gnome with a red hat and beard");
        setCompanyImage(GnomeCompanyImage);
        break;
      case Company["Elephant Enterprises"]:
        setAltText("Face of an elephant");
        setCompanyImage(ElephantCompanyImage);
        break;
    }
  }, [companyName]);

  return (
    <PageTemplate
      testId="progress-through-the-year"
      onClick={() => {
        if (timelineReport.currentQuarter === stepper && timelineReport.currentYear === 3) {
          updateTimeline(
            produce(timelineReport, draft => {
              draft.currentQuarter = 0;
            }),
          );
        } else if (timelineReport.currentQuarter === stepper) {
          const newTimelineReport = produce(timelineReport, draft => {
            getCurrentYear(draft, metrics).quarters[3] = { quarter: true, metrics: metrics };
            draft.currentYear = draft.currentYear + 1;
            draft.currentQuarter = 1;
            draft.calendarYearsInfo[draft.currentYear - 1].quarters[0].quarter = true;
          });

          updateTimeline(newTimelineReport);
        } else {
          const newTimelineReport = produce(timelineReport, draft => {
            const currentYearState = getCurrentYear(draft, metrics);
            currentYearState.quarters[draft.currentQuarter] = { quarter: true, metrics: metrics };
            const newCalendarYearsInfo = draft.calendarYearsInfo.map(yearReport => {
              if (yearReport.count === draft.currentYear) {
                yearReport = currentYearState;
              }
              return yearReport;
            });
            draft.calendarYearsInfo = newCalendarYearsInfo;
            draft.currentQuarter = draft.currentQuarter + 1;
          });

          updateTimeline(newTimelineReport);
        }
      }}
      includeFooter={true}
      buttonIcon={true}
    >
      <WideNavigation
        dataTestId="your-report"
        title={
          fullGameMode
            ? timelineReport.currentQuarter === 0
              ? "Your Starting Report"
              : "Your Quarterly Report"
            : "Your Starting Report"
        }
        iconName={fullGameMode ? "" : "close"}
        confirmExit={onExitTutorial as () => void}
        isTutorial={!fullGameMode}
      >
        <YearlyTimelines timelines={timelineReport} />
        <Container style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
          <CompanyImage
            src={companyImage}
            data-testid={GenerateID(companyName)}
            style={{ display: "initial", left: "-30px" }}
            alt={altText}
          />
          <div style={{ textAlign: "center", maxWidth: "180px" }}>
            <h3 style={{ display: "inline" }} data-testid={`${playerName}-header`}>
              {playerName}
            </h3>
            <p>CIO, {companyName}</p>
          </div>
        </Container>
      </WideNavigation>
      <ContentContainer style={{ maxWidth: "700px", margin: "auto", justifyContent: "left" }}>
        <MetricsGrid metrics={metrics} report={timelineReport} getArrowImage={getArrowImage} />
        <YearlyTrendsPanel report={timelineReport} company={companyName} />
      </ContentContainer>
    </PageTemplate>
  );
};
export default MetricsReport;
