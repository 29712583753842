import * as React from "react";
import { Container } from "semantic-ui-react";
import NextButton from "../../shared/components/NextButton";
import Gnome from "../../assets/images/lineImages/gnome_line.svg";
import Elephant from "../../assets/images/lineImages/elephant_line.svg";
import Bagel from "../../assets/images/lineImages/bagel_line.svg";
import ReactGA from "react-ga4";
import ImageLayout from "../../shared/components/ImageLayout";
import { StyledContainer } from "../../shared/components/StyledContainer";
import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";

const TutorialContainer = styled(StyledContainer)`
  @media ${device.tablet} {
    > p:last-of-type {
      margin-bottom: 50px;
    }
  }
  @media ${device.laptop} {
    width: 500px;
    margin-left: calc(50% - 500px / 2) !important;
    > p:last-of-type {
      margin-bottom: 50px;
    }
  }
`;

interface TutorialSelectProps {
  playerName: string;
  setTutorial: (tutorial: boolean) => void;
  onDone: () => void;
}

const GameModeSelected = (onDone: () => void, mode: string) => {
  ReactGA.event({
    category: "GameSetup",
    action: `Game mode selected: ${mode}`,
  });
  onDone();
};

const TutorialSelect: React.FC<TutorialSelectProps> = ({ playerName, setTutorial, onDone }) => {
  const [timeLoaded] = React.useState(new Date().getTime());

  React.useEffect(() => {
    return () => {
      ReactGA.event({
        category: "Tutorial Select duration",
        action: ((new Date().getTime() - timeLoaded) / 1000).toString(),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ImageLayout includeNav={false} GnomeSource={Gnome} BagelSource={Bagel} ElephantSource={Elephant}>
      <TutorialContainer>
        <div style={{ margin: "5em" }} />
        <h1 style={{ overflowWrap: "break-word" }} data-testid="welcomeNameText">
          Hi {playerName},
        </h1>
        <p data-testid="firstTimeQuestion">Is this your first time playing?</p>
        <p>
          <strong>Try our quick tutorial</strong> to learn how to play the game.
        </p>
        <p>
          Or, jump straight in and <strong>start!</strong>
        </p>
        <Container style-={{ margin: "10em" }}>
          <NextButton
            testId="tutorialStart"
            disabled={false}
            onClick={() => {
              setTutorial(true);
              GameModeSelected(onDone, "Tutorial");
            }}
            text="Try our quick tutorial"
            buttonIcon={true}
          />
          <br />
          <NextButton
            testId="gameStart"
            disabled={false}
            onClick={() => {
              setTutorial(false);
              GameModeSelected(onDone, "Main Game");
            }}
            text="Start game"
            buttonIcon={true}
          />
        </Container>
      </TutorialContainer>
    </ImageLayout>
  );
};

export default TutorialSelect;
