import { IInitiativeSelection } from "../pages";
import { findInitiative } from "./findInitiative";

export const disableInitiativeButton = (
  isTutorial: boolean,
  initiative: IInitiativeSelection["initiativeName"],
  currentYearInitiatives: IInitiativeSelection[],
  previousYearsInitiatives: IInitiativeSelection[],
  fundLimit: number,
) => {
  let disabled = false;
  if (isTutorial && initiative !== "DevOps Transformation") return true;
  if (currentYearInitiatives.length >= fundLimit) {
    if (!findInitiative(currentYearInitiatives, initiative)) return true;
  } else {
    if (findInitiative(previousYearsInitiatives, initiative)) return true;
  }
  return disabled;
};
