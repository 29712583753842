import React from "react";
import ReactGA from "react-ga4";
import styled from "styled-components";

const LinkContainer = styled.div`
  > a > p {
    font-family: Mukta;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    text-decoration-line: underline;
    color: #004a64;
  }
`;

const Links = () => {
  const LinkSelected = (pageName: string) => {
    ReactGA.event({
      category: "End game",
      action: `Click Through: ${pageName}`,
    });
  };

  return (
    <LinkContainer>
      <a
        href="https://wayfinder.ninja/"
        onClick={() => {
          LinkSelected("Learn more about WayFinder");
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>Learn more about WayFinder</p>
      </a>
      <a
        href="https://devops.games/pages/stateOfDevOps.html"
        onClick={() => {
          LinkSelected("Learn more about DORA State of DevOps");
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>Learn more about DORA State of DevOps</p>
      </a>
      <a
        href="https://devops.games/pages/improveYourSDO.html"
        onClick={() => {
          LinkSelected("How do I improve my score");
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>How do I improve my score?</p>
      </a>
      <a
        href="https://devops.games/pages/learnMoreAboutScore.html"
        onClick={() => {
          LinkSelected("Learn more about your score");
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>Learn more about your score</p>
      </a>
      <a
        href="https://devops.games/pages/aboutTheGame.html"
        onClick={() => {
          LinkSelected("Read more about the game");
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>Read more about the game</p>
      </a>
      <a
        href="https://www.mechanicalrock.io/"
        onClick={() => {
          LinkSelected("About Mechanical Rock");
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>About Mechanical Rock </p>
      </a>
      <a
        href="https://forms.gle/KxrDzhCt4XJqFotx9"
        onClick={() => {
          LinkSelected("Give Us feedback");
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>Give Us Feedback </p>
      </a>
    </LinkContainer>
  );
};

export default Links;
