import * as React from "react";
import { IMetrics } from "../../../interfaces";
import WhatHappened from "./WhatHappened";
import NowWhat1 from "./NowWhat1";
import NowWhat2 from "./NowWhat2";
import WhatsAnInitiative from "./WhatsAnInitiative";
import { Company } from "../../../app";

export interface ITutorialProps {
  isTutorial: boolean;
  updateGameState: (setTutorial: boolean) => void;
  onExitTutorial: () => void;
  onExitGame: () => void;
  metrics: IMetrics;
  name: string;
  companyName: Company;
  onTutorialWhatsInitiatives: () => void;
  pageToGo: string;
}

const Tutorial = ({
  isTutorial,
  onExitTutorial,
  metrics,
  name,
  onExitGame,
  updateGameState,
  companyName,
  pageToGo,
  onTutorialWhatsInitiatives,
}: ITutorialProps) => {
  const [page, setPage] = React.useState(pageToGo);
  const close = () => {
    onExitTutorial();
  };
  switch (page) {
    case "whatsAnInitiative":
      return (
        <WhatsAnInitiative
          companyName={companyName}
          onClose={close}
          isTutorial={isTutorial}
          onDone={onTutorialWhatsInitiatives}
        />
      );
    case "whatHappened":
    default:
      return (
        <WhatHappened
          onDone={() => {
            setPage("nowWhat1");
          }}
          onClose={close}
          isTutorial={isTutorial}
          metrics={metrics}
        />
      );
    case "nowWhat1":
      return (
        <NowWhat1
          onDone={() => {
            setPage("nowWhat2");
          }}
          onClose={close}
          isTutorial={isTutorial}
          name={name}
        />
      );
    case "nowWhat2":
      return <NowWhat2 onClose={close} isTutorial={isTutorial} onExitGame={onExitGame} setTutorial={updateGameState} />;
  }
};

export default Tutorial;
