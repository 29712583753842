import { initiatives as listOfInitiatives } from "./index";
import { IMetrics, Initiative, InitiativeEffect } from "../../interfaces";
import { IInitiativeSelection } from "../pages";
import { calculateMetrics, EngineVisitor } from "../../gameEngine/domain_model/scoreEngine/functions";
import { limitMetricsTo100 } from "../../shared/helpers/capMetricsAt100";

interface FundingInfo {
  selectedFundingLevel: number;
  totalFundingLevels: number;
}
function initiativeEffect(effect: InitiativeEffect, fundingInfo: FundingInfo): number {
  const diffGap = Number(effect.max) - Number(effect.min);
  const costPerOption = diffGap / fundingInfo.totalFundingLevels;
  const minCost = Number(effect.min);
  return minCost + fundingInfo.selectedFundingLevel * costPerOption;
}

function getFundingInfo(initiative: Initiative, fundedOption: string) {
  return {
    selectedFundingLevel: initiative.label.indexOf(fundedOption),
    totalFundingLevels: initiative.label.length - 1,
  };
}

function initiativeByName(name: string): Initiative | undefined {
  return listOfInitiatives.find(i => i.initiative === name);
}

export const metricsVariableAndCost = (
  selectedInitiatives: IInitiativeSelection[],
  metrics: IMetrics,
  engineVisitor?: EngineVisitor,
) => {
  let newMetrics: IMetrics = metrics;

  selectedInitiatives.forEach((item: { initiativeName: string; option: string; fundedYear: number }) => {
    const initiative = initiativeByName(item.initiativeName);
    initiative?.effects.map(effect => {
      newMetrics = calculateMetrics(
        effect.variables,
        initiativeEffect(effect, getFundingInfo(initiative, item.option)),
        engineVisitor,
      )(newMetrics);
    });
  });
  newMetrics = limitMetricsTo100(newMetrics);
  return newMetrics;
};
