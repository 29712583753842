import React from "react";
import { Event, IChoice, IMetrics } from "../../interfaces";
import EventOptions from "../components/EventOptions";
import { shuffleArray } from "../../shared/helpers/arrayTools";
import { calculateEventMetrics } from "../../gameEngine/domain_model/calculateEventMetrics";
import ReactGA from "react-ga4";
import { IEventsSelection, addEvent } from "../domain_model/eventList";
import { eventOccurs, returnUnusedEvents } from "../domain_model";
import PageLayout from "../../shared/components/PageTemplate";
import { StyledContainer } from "../../shared/components/StyledContainer";

interface EventProps {
  events: Event[];
  isTutorial: boolean;
  metrics: IMetrics;
  selectedEvents: IEventsSelection[];
  eventSet: (Events: Event[], newMetrics: IMetrics, eventState: IEventsSelection[]) => void;
  onExitTutorial: () => void;
  updateEvents: (events: Event[]) => void;
}

const EventSelection: React.FC<EventProps> = ({
  events,
  updateEvents,
  isTutorial,
  onExitTutorial,
  eventSet,
  metrics,
  selectedEvents,
}) => {
  const [eventList, setEventList] = React.useState(events);
  const [selectedEvent, setSelectedEvent] = React.useState<Event>({ name: "", description: "", choices: [] });
  const [eventOptions, setEventOptions] = React.useState<IChoice[]>(selectedEvent.choices);
  const [selectedEventChoice, setEventChoice] = React.useState<IChoice>({ choice: "", nodes: [] });

  React.useEffect(
    () => {
      // FIXME - there is a disjoint between an event occurring (here), and removing the event from the state
      // The two should occur in the same place.
      const selectedEvent = eventOccurs(events);
      setSelectedEvent(selectedEvent);
      ReactGA.event({
        category: "Event",
        action: `Name: ${selectedEvent.name}`,
      });
      if (selectedEvent) {
        setEventOptions(shuffleArray(selectedEvent.choices));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClick = () => {
    const events = returnUnusedEvents(eventList, selectedEvent);
    setEventList(events);
    updateEvents(events);
    ReactGA.event({
      category: "Event",
      action: `Option selected: ${selectedEventChoice.choice}.`,
    });
    const newMetrics = calculateEventMetrics(selectedEventChoice, metrics);
    const UpdatedEventList = addEvent({
      selectedEvents: selectedEvents,
      eventName: selectedEvent.name,
      option: selectedEventChoice.choice,
    });
    eventSet(events, newMetrics, UpdatedEventList);
  };

  return (
    <PageLayout
      title={"Event"}
      confirmExit={onExitTutorial}
      isTutorial={isTutorial}
      icon={"close"}
      testId={"make-event-decision"}
      disabled={!selectedEventChoice.choice}
      onClick={handleClick}
      includeFooter={true}
      includeNav={true}
      buttonIcon={true}
    >
      <StyledContainer>
        <h3 data-testid="event-name">{selectedEvent.name}</h3>
        <p style={{ textAlign: "left" }} data-testid="event-description">
          {selectedEvent.description}
        </p>
        <EventOptions eventChoices={eventOptions} setEventOption={setEventChoice} isTutorial={isTutorial} />
      </StyledContainer>
    </PageLayout>
  );
};

export default EventSelection;
