import * as React from "react";

import styled from "styled-components";

import Metrics from "./Metrics";
import { IMetrics } from "../../interfaces/metrics";
import { Company } from "../../app";
import PageLayout from "../../shared/components/PageTemplate";
import { StyledContainer } from "../../shared/components/StyledContainer";
import MetricsSpeech from "../components/MetricsSpeech";
import { device } from "../../shared/helpers/deviceConfig";

const MetricsText = styled.div`
  > p {
    max-width: 650px;
    height: 116px;
    left: 36px;
    top: 310px;
  }
  @media ${device.mobileS} {
    > p {
      width: 303px;
    }
  }
  @media ${device.mobileM} {
    > p {
      width: 316px;
    }
  }
  @media ${device.mobileL} {
    > p {
      width: 316px;
    }
  }
  @media ${device.tablet} {
    > p {
      width: auto;
    }
  }
  @media ${device.laptop} {
    > p {
      width: auto;
    }
  }
  @media ${device.laptopL} {
    > p {
      width: auto;
    }
  }
  @media ${device.desktop} {
    > p {
      width: auto;
    }
  }
  @media ${device.desktopL} {
    > p {
      width: auto;
    }
  }
`;

const WhatAreMetricsStyledContainer = styled(StyledContainer)`
  text-align: left;
  margin-bottom: 2rem;
`;
interface IWhatAreMetrics {
  companyName: Company;
  onClose: () => void;
  onDone: () => void;
  isTutorial: boolean;
  metrics: IMetrics;
}

const WhatAreMetrics: React.FC<IWhatAreMetrics> = ({ companyName, onClose, isTutorial, onDone, metrics }) => {
  return (
    <PageLayout
      title={"Now, what are Metrics?"}
      confirmExit={onClose}
      icon={"close"}
      disabled={false}
      onClick={onDone}
      includeNav={true}
      includeFooter={true}
      isTutorial={isTutorial}
      buttonIcon={true}
    >
      <WhatAreMetricsStyledContainer>
        <MetricsSpeech />
        <MetricsText>
          <p>
            <strong>Metrics</strong> are the 4 top level measures which are real-world indicators of{" "}
            <strong>high performing software development and organisational performance.</strong>
          </p>
        </MetricsText>
        <Metrics metrics={metrics} displayHelperText={true} />
        <div style={{ marginTop: "4rem" }}>
          <p>
            <strong>How to win</strong>
          </p>
          <p>In order to be a successful and outstanding CIO, keep all 4 metrics as high as you can!</p>
        </div>
      </WhatAreMetricsStyledContainer>
    </PageLayout>
  );
};
export default WhatAreMetrics;
