import { findInitiative } from "./findInitiative";
import { IInitiativeSelection } from "../pages";
import { findIndexOfOption, initiativeCost } from "./updateBudget";

interface IAddInitiative {
  selectedInitiatives: IInitiativeSelection[];
  selectedInitiative: IInitiativeSelection["initiativeName"];
  optionSelected: IInitiativeSelection["option"];
  currentYear: number;
}
interface IRemoveInitiative {
  selectedInitiatives: IInitiativeSelection[];
  selectedInitiative: IInitiativeSelection["initiativeName"];
}

export const addInitiative = ({
  selectedInitiatives,
  selectedInitiative,
  optionSelected,
  currentYear,
}: IAddInitiative) => {
  //just an extra check to see if initiative exist then removes it.
  let playerInitiativeList = updateInitiativeList(selectedInitiatives, selectedInitiative);
  const playerInitiative: IInitiativeSelection = {
    initiativeName: selectedInitiative,
    option: optionSelected,
    fundedYear: currentYear,
  };

  playerInitiativeList.push(playerInitiative);
  return playerInitiativeList;
};

export const removeInitiative = ({ selectedInitiatives, selectedInitiative }: IRemoveInitiative) => {
  const updatedList = selectedInitiatives.filter(obj => obj.initiativeName !== selectedInitiative);
  return updatedList;
};

interface revalidateCostProps {
  selectedInitiatives: IInitiativeSelection[];
  playerInitiative: IInitiativeSelection["initiativeName"];
  initiativeMinCost: number;
  optionCost: number;
}
export const revalidateCost = ({
  selectedInitiatives,
  playerInitiative,
  initiativeMinCost,
  optionCost,
}: revalidateCostProps) => {
  const initiativeExist = findInitiative(selectedInitiatives, playerInitiative);
  let costOfOldOption = 0;
  if (selectedInitiatives.length > 0) {
    if (initiativeExist) {
      //should calculate the previous option
      const indexOfOption = findIndexOfOption(selectedInitiatives, playerInitiative);
      costOfOldOption = initiativeCost(indexOfOption, initiativeMinCost, optionCost);
    }
  }
  return costOfOldOption;
};

export const findPreviousOption = (selectedInitiatives: IInitiativeSelection[], playerInitiative: string) => {
  let previousOption = "";
  selectedInitiatives.forEach(initiative => {
    if (initiative.initiativeName === playerInitiative) {
      previousOption = initiative.option;
    }
  });
  return previousOption;
};

//Ensures initiative is only populating the initiatives array once.
//Checks if initiative exists then it'll remove the old one
export const updateInitiativeList = (
  selectedInitiatives: IInitiativeSelection[],
  selectedInitiative: IInitiativeSelection["initiativeName"],
) => {
  let updatedInitiativeList: IInitiativeSelection[] = selectedInitiatives;
  const initiativeExist = findInitiative(selectedInitiatives, selectedInitiative);
  if (initiativeExist) {
    updatedInitiativeList = selectedInitiatives.filter(initiative => initiative.initiativeName !== selectedInitiative);
  }
  return updatedInitiativeList;
};
