import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";

export const StyledInitiativeContainer = styled.div`
  text-align: left;
  margin-top: 10px;
  border-radius:10px;
  border-top: 1px solid #BDBDBD;
  border-left: 1px solid #BDBDBD;
  border-right: 1px solid #BDBDBD;

  @media ${device.mobileS} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }
  @media ${device.mobileM} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }
  @media ${device.mobileL} {
    max-width: 300px;
    margin-left: calc(50% - 300px / 2);
  }
  @media ${device.tablet} {
    max-width: 500px;
    margin-left: calc(50% - 500px / 2);

  }
  @media ${device.tabletPro} {
    text-align: center;
    max-width: 500px;
    margin-left: calc(50% - 500px / 2);

  }
  @media ${device.tabletPro} {
    text-align: center;
    max-width: 500px;
    margin-left: calc(50% - 500px / 2);
  }
  @media ${device.laptop} {
    text-align: center;
    max-width: 550px;
    margin-left: calc(50% - 550px / 2);
   ]
  }
  @media ${device.fullHDTV720p} {
    text-align: center;
    max-width: 550px;
    margin-left: calc(50% - 550px / 2);

  }
  @media ${device.laptopL} {
    text-align: center;
    max-width: 550px;
    margin-left: calc(50% - 550px / 2);

  }
  @media ${device.fullHDTV1080p} {
    text-align: center;
    max-width: 700px;
    margin-left: calc(50% - 700px / 2);

  }
  @media ${device.desktop} {
    text-align: center;
    max-width: 700px;
    margin-left: calc(50% - 700px / 2);
  }
  @media ${device.desktopL} {
    text-align: center;
    max-width: 1000px;
    margin-left: calc(50% - 1000px / 2);

  }
`;
