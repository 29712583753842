import styled from "styled-components";
import React, { FC } from "react";
import NavBar from "./NavBar";
import { Image } from "semantic-ui-react";
import { device } from "../helpers/deviceConfig";

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Navigation = styled.div`
  max-width: 100%;
`;

const Body = styled.div`
  margin: 0;
  padding: 0;
  with: 100%;
`;

const GnomeImage = styled(Image)`
  position: absolute !important;
  bottom: 0px;
    @media ${device.mobileS} {
    left: 70px;
  }
  @media ${device.mobileM} {
    left: 99px;
  }
  @media ${device.mobileL} {
    left: 119px;
  }
  @media ${device.tablet} {
    left: 97px;
  }
  @media ${device.laptop} {
    left: 222px;
  }
  @media ${device.laptopL} {
    left: 366px;
  }

  @media ${device.desktop} {
    left: 30%;
  }
  @media ${device.desktopL} {
    left: 30%;
  }
}
`;

const BagelImage = styled(Image)`
  position: absolute !important;
  left: -0.43px;
  top: 500px;
  transform: rotate(0.76deg);
  @media ${device.mobileS} {
    top: 355px;
  }
`;

const ElephantImage = styled(Image)`
  position: absolute !important;
  bottom: 13.35%;
  top: 500px;
  right: 0px;

  @media ${device.mobileS} {
    top: 397px;
  }
`;

interface ILayoutProps {
  title?: string;
  confirmExit?: () => void;
  isTutorial?: boolean;
  includeNav: boolean;
  icon?: string;
  GnomeSource: string;
  BagelSource: string;
  ElephantSource: string;
  // children is implicitly imported as part of FC
}

const ImageLayout: FC<ILayoutProps> = ({
  title,
  confirmExit,
  isTutorial,
  includeNav,
  icon,
  GnomeSource,
  BagelSource,
  ElephantSource,
  children,
}) => (
  <Container>
    {includeNav && (
      <Navigation>
        <NavBar title={title} iconName={icon} confirmExit={confirmExit} isTutorial={isTutorial} />
      </Navigation>
    )}
    <Body>
      {children}
      <GnomeImage src={GnomeSource} alt={"Gnome"} />
      <BagelImage src={BagelSource} alt={"Bagel"} />
      <ElephantImage src={ElephantSource} alt={"Elephant"} />
    </Body>
  </Container>
);

export default ImageLayout;
