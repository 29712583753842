import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./typography.css";
import "semantic-ui-css/semantic.min.css";
import App from "./app";
import ReactGA from "react-ga4";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`);

ReactDOM.render(<App />, document.getElementById("root"));
