import * as React from "react";
import { Image } from "semantic-ui-react";
import BannerComponent from "../../../company/components/Banner";
import whatsAnInitiative from "../../../assets/images/WhatsAnInitiativesBanner.svg";
import styled from "styled-components";
import speechBubble from "../../../assets/images/initiative-speech.svg";
import { Company } from "../../../app";
import { StyledContainer } from "../../../shared/components/StyledContainer";
import PageLayout from "../../../shared/components/PageTemplate";
import { device } from "../../../shared/helpers/deviceConfig";

const InitiativeText = styled.div`
  text-align: left;
  max-width: 500px;
  margin: 80px auto 50px;
  padding: 0.5rem;
`;

const ImageContainer = styled.div`
  @media ${device.mobileS} {
    overflow: hidden;
  }
  @media ${device.mobileM} {
    overflow: hidden;
  }
  @media ${device.mobileL} {
    overflow: hidden;
  }
`;

const BottomImage = styled(Image)`
  @media ${device.mobileS} {
    margin: 14px auto -60px;
  }
  @media ${device.mobileM} {
    margin: 64px auto -60px;
  }
  @media ${device.mobileL} {
    margin: 64px auto -60px;
  }

  @media ${device.tablet} {
    margin: 80px auto 0;
  }
  @media ${device.laptop} {
    margin: 80px auto 0;
  }
  @media ${device.fullHDTV720p} {
    margin: 80px auto 2rem;
  }
  @media ${device.laptopL} {
    margin: 14px auto;
  }

  @media ${device.fullHDTV1080p} {
    margin: 80px auto 0;
  }
  @media ${device.desktop} {
    margin: 14px auto;
  }
  @media ${device.desktopL} {
    margin: 80px auto 0;
  }
`;
interface IWhatsAnInitiative {
  companyName: Company;
  onClose: () => void;
  onDone: () => void;
  isTutorial: boolean;
}

const WhatsAnInitiative: React.FC<IWhatsAnInitiative> = ({ companyName, onClose, isTutorial, onDone }) => {
  return (
    <PageLayout
      title={"What's an Initiative?"}
      confirmExit={onClose}
      isTutorial={isTutorial}
      testId={"letsChooseInitiative"}
      text={"Let's Try One"}
      onClick={onDone}
      includeFooter={true}
      icon={"close"}
      includeNav={true}
      buttonIcon={false}
    >
      <StyledContainer>
        <BannerComponent imageSrc={whatsAnInitiative} alt={"DevOps infinity logo with a cloud behind it."} />
        <InitiativeText>
          <p>
            <strong>Initiatives</strong> are<strong> proactive options</strong> that lay the foundation of your digital
            strategy.
          </p>
          <p>
            They will cost a portion of your budget and <strong> you have to decide how much to invest.</strong>
          </p>
        </InitiativeText>
        <ImageContainer>
          <BottomImage
            src={speechBubble}
            alt={
              "Bagel logo with speech bubble saying: Some Initiatives are easy to implement, some have more impact and some are quite risky."
            }
          />
        </ImageContainer>
      </StyledContainer>
    </PageLayout>
  );
};
export default WhatsAnInitiative;
