import { IInitiativeSelection } from "../pages";

export const findCurrentYearInitiatives = (selectedInitiatives: IInitiativeSelection[], currentYear: number) => {
  let yearInititiatives: IInitiativeSelection[] = [];
  selectedInitiatives.map(initiative => {
    if (initiative.fundedYear === currentYear) {
      yearInititiatives.push(initiative);
    }
    return yearInititiatives;
  });

  return yearInititiatives;
};
