import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Company } from "../../app";
import { Icon } from "semantic-ui-react";

interface props {
  company: Company;
  score: number;
  imageURL: string;
}
const SocialMedia: React.FC<props> = props => {
  const [copied, setCopied] = React.useState(false);
  const fqUrl = "https://dream.devops.games/scoreV3";

  const clipboardText = `Have you played #DevOpsDream? I just scored ${props.score}% as CIO of ${props.company}!
    Can you beat my score? via @DevOps_Dream  \n\r ${fqUrl}/${props.imageURL}/index.html \n\r #Serverless #DevOps`;

  const shareText = `Have you played #DevOpsDream? I just scored ${props.score}% as CIO of ${props.company}!
Can you beat my score? via @DevOps_Dream  \n\r`;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
        <TwitterShareButton
          url={`${fqUrl}/${props.imageURL}/index.html \n\r`}
          className="socialMediaButton"
          title={shareText}
          hashtags={["Serverless", "DevOps"]}
          data-testid="twitter-btn"
        >
          <TwitterIcon size={36} round style={{ marginRight: "1rem" }} />
        </TwitterShareButton>

        <FacebookShareButton
          url={`${fqUrl}/${props.imageURL}/index.html \n\r`}
          className={"socialMediaButton"}
          quote={shareText}
          hashtag={`#Serverless #DevOps`}
          data-testid="facebook-btn"
        >
          <FacebookIcon size={36} round style={{ marginRight: "1rem" }} />
        </FacebookShareButton>

        <LinkedinShareButton
          url={`${fqUrl}/${props.imageURL}/index.html \n\r`}
          className={"socialMediaButton"}
          title={shareText}
          data-testid="linkedin-btn"
        >
          <LinkedinIcon size={36} round style={{ marginRight: "1rem" }} />
        </LinkedinShareButton>

        <WhatsappShareButton
          url={`${fqUrl}/${props.imageURL}/index.html \n\r`}
          className={"socialMediaButton"}
          title={shareText}
          data-testid="whatsapp-btn"
        >
          <WhatsappIcon size={36} round style={{ marginRight: "1rem" }} />
        </WhatsappShareButton>

        <CopyToClipboard text={clipboardText} onCopy={() => setCopied(true)}>
          <Icon
            name="copy outline"
            style={{ marginTop: ".3rem" }}
            data-testid="copy-to-clipboard-btn"
            size="big"
          ></Icon>
        </CopyToClipboard>
        {copied && (
          <span style={{ color: "red" }} data-testid="copied-text">
            Copied
          </span>
        )}
      </div>
    </>
  );
};

export default SocialMedia;
