import * as React from "react";
import { Image } from "semantic-ui-react";
import welcomeCIOBlockChainBagelsImage from "../../assets/images/welcomeCIO/welcomeCIO_BlockChainbagel.svg";
import welcomeCIOElephantEntImage from "../../assets/images/welcomeCIO/welcomeCIO_ElephantEnt.svg";
import welcomeCIOGnomesImage from "../../assets/images/welcomeCIO/welcomeCIO_Gnomes.svg";
import bagelSpeech from "../../assets/images/welcomeCIO/BagelSpeech.svg";
import gnomeSpeech from "../../assets/images/welcomeCIO/GnomeSpeech.svg";
import elephantSpeech from "../../assets/images/welcomeCIO/ElephantSpeech.svg";
import elephantInset from "../../assets/images/welcomeCIO/ElephantInset.svg";
import gnomeInset from "../../assets/images/welcomeCIO/GnomeInset.svg";
import bagelInset from "../../assets/images/welcomeCIO/BagelInset.svg";
import styled from "styled-components";
import { Company } from "../../app";
import { GenerateID } from "../../shared/helpers/testIdGenerator";
import PageLayout from "../../shared/components/PageTemplate";
import { StyledContainer } from "../../shared/components/StyledContainer";
import { device } from "../../shared/helpers/deviceConfig";

interface IWelcomeCIO {
  playerName: string;
  companyName: Company;
  onDone: () => void;
  onClose: () => void;
  isTutorial: boolean;
}
const Banner = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 1rem;
`;

const BlurbContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 1rem;
`;
const TextContent = styled.div`
  display: flex;
  grid-column: 1;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  padding: 1rem;
`;
const LogoContainer = styled.div`
  grid-column: 3;
  display: grid;
  justify-content: flex-end;
  justify-items: start;
  align-content: flex-start;
  grid-template-rows: auto;
`;
const InsetImage = styled(Image)`
  position: absolute !important;
  top: 500px;
  right: 0px;
  @media ${device.mobileS} {
    top: 500px;
    height: 115px;
  }
  @media ${device.galaxyS} {
    height: 135px;
  }
  @media ${device.tablet} {
    top: 541px;
    height: 160px;
    right: 43px;
  }
  @media ${device.fullHDTV720p} {
    right: 300px;
  }
  @media ${device.fullHDTV1080p} {
    right: 500px;
  }
  @media ${device.desktopL} {
    right: 1300px;
  }
`;

const SpeechImage = styled(Image)`
  justify-self: end;
  align-self: self-end;
  grid-row: 1;
  margin: 0;
  padding: 0;
`;

const InsetTextAlignment = styled.div`
  width: 195px;
  font-size: 16px;
  margin-bottom: 50px;
  @media ${device.tablet} {
    width: 405px;
    margin-bottom: 28px;
  }
  @media ${device.fullHDTV1080p} {
    width: 605px;
  }
`;

const blockchainBagelsBlurb = (
  <>
    <p>
      As you know, we've been <strong>running for 12 months</strong> now and we're starting to take-off, due to the
      growth in ethically sourced baked goods.
    </p>
    <InsetTextAlignment>
      We expect to see our <strong>customers and revenue treble every 6 months</strong>, but frankly we don't know how
      we're going to cope.
    </InsetTextAlignment>
    <p>
      Spirits are high and <strong>customers love our product</strong>. But we need to move fast, ship new features and
      support our fragile digital systems, which are creaking under the load.{" "}
      <strong>We need YOU to implement a digital strategy to take us forward!</strong>
    </p>
  </>
);
const networkedGnomesBlurb = (
  <>
    <p>
      As you know, we've been <strong>running for 7 years</strong> and our biggest problem is knowing what to tackle
      next."
    </p>
    <InsetTextAlignment>
      We want to expand our team and products with new technologies like AI, machine learning and self-service data
      analytics –
    </InsetTextAlignment>
    <p>
      but we're not sure how to set up our internal teams to succeed.They have their hands full running the current
      systems.
    </p>
    <p>
      How do we keep the lights on for current customers while developing new products and solutions to attract new
      business in the market?
    </p>
  </>
);
const elephantEnterprisesBlurb = (
  <>
    <InsetTextAlignment style={{ marginBottom: "60px" }}>
      As you know, we've been <strong>running for 25 years</strong>, but size comes at a cost. Although we're good at
      what we do, smaller competitors are taking our market share."
    </InsetTextAlignment>
    <p>We need to reinvent ourselves as a digital company and become more nimble and innovative.</p>
    <p>
      The challenge is large - our people are good and our systems are stable but we aren't able to iterate quickly and
      drive out the new systems and apps that the market is demanding. We need to maintain system reliability while
      building the capability to move faster.
    </p>
  </>
);

const WelcomeCIO: React.FC<IWelcomeCIO> = ({ playerName, companyName, onDone, onClose, isTutorial }) => {
  const [blurb, setBlurb] = React.useState<JSX.Element>();
  const [welcomeBanner, setBanner] = React.useState("");
  const [insetImage, setInsetImage] = React.useState("");
  const [speech, setSpeech] = React.useState("");
  const [altText, setAltText] = React.useState("");
  const [altTextInset, setAltTextInset] = React.useState("");

  React.useEffect(() => {
    switch (companyName) {
      case Company["Blockchain Bagels"]:
        setBlurb(blockchainBagelsBlurb);
        setBanner(welcomeCIOBlockChainBagelsImage);
        setInsetImage(bagelInset);
        setSpeech(bagelSpeech);
        setAltText("Bagel banner");
        setAltTextInset("Slice of cheese and spinach");
        break;
      case Company["Networked Gnomes"]:
        setBlurb(networkedGnomesBlurb);
        setBanner(welcomeCIOGnomesImage);
        setInsetImage(gnomeInset);
        setSpeech(gnomeSpeech);
        setAltText("Gnome banner");
        setAltTextInset("Raindrops");
        break;
      case Company["Elephant Enterprises"]:
        setBlurb(elephantEnterprisesBlurb);
        setBanner(welcomeCIOElephantEntImage);
        setInsetImage(elephantInset);
        setSpeech(elephantSpeech);
        setAltText("Elephant banner");
        setAltTextInset("Mobile phone and cogs");
        break;
    }
  }, [companyName]);

  return (
    <PageLayout
      title="Welcome CIO"
      confirmExit={onClose}
      isTutorial={isTutorial}
      disabled={false}
      onClick={onDone}
      includeFooter={true}
      includeNav={true}
      icon="close"
      buttonIcon={true}
    >
      <StyledContainer>
        <Banner data-testid="company-banner">
          <Image src={welcomeBanner} alt={altText} />
        </Banner>
        <BlurbContent data-testid={`page-content-${GenerateID(companyName)}`}>
          <TextContent>
            <p style={{ overflowWrap: "break-word", paddingTop: "20px" }} data-testid="welcome-message">
              Welcome to{" "}
              <strong>
                {companyName}, {playerName}!
              </strong>
            </p>
            <p>
              We’re excited to have you as our new <strong>Chief Information Officer</strong>!
            </p>
            {blurb}
          </TextContent>
          <LogoContainer data-testid="rightSide-logo">
            <InsetImage src={insetImage} data-testid={`${GenerateID(companyName)}`} alt={altTextInset} />
          </LogoContainer>
        </BlurbContent>
        <SpeechImage src={`${speech}`} alt="company speech" data-testid="speech-pic" />
      </StyledContainer>
    </PageLayout>
  );
};

export default WelcomeCIO;
