import * as React from "react";
import styled from "styled-components";
import { Icon, Confirm } from "semantic-ui-react";

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  grid-template-rows: 2rem;
  font-weight: bold;
  font-size: 18px;
  align-items: baseline;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  > p {
    grid-column: 2;
    justify-self: center;
    font-family: Arial;
  }
`;
const CloseIcon = styled(Icon)`
  justify-self: flex-end;
  grid-column: 3;
  grid-row: 1;
  margin-right: 2rem;
`;
export interface INavBar {
  title?: string;
  iconName?: string;
  confirmExit?: () => void;
  isTutorial?: boolean;
}
const NavBar: React.FC<INavBar> = ({ title, iconName, confirmExit, isTutorial, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const showConfirmation = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <Container className="navBar-title" data-testid="page-navbar">
      {isTutorial && (
        <CloseIcon
          role="button"
          data-testid="exit-tutorial-btn"
          name={iconName}
          size="small"
          onClick={showConfirmation}
          style={{ marginRight: "1rem" }}
        />
      )}
      <p>{title}</p>
      {children}
      <Confirm
        data-testid="confirmMessage"
        open={isOpen}
        header="Confirm exit tutorial"
        content="Are you sure you want to exit the tutorial?"
        cancelButton="Cancel"
        confirmButton="Exit Tutorial"
        onCancel={handleCancel}
        onConfirm={confirmExit}
      />
    </Container>
  );
};
export default NavBar;
