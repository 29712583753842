import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

interface IErrorMessage {
  textMessage: string;
}
const ErrorMessageBox = styled.div`
  display: flex;
  align-items: baseline;
  > small {
    font-weight: 400;
    color: red;
    padding: 0.5rem;
  }
`;
const ErrorMessage = ({ textMessage }: IErrorMessage) => {
  return (
    <ErrorMessageBox>
      <Icon name="warning sign" />
      <small data-testid="error-message">{textMessage}</small>
    </ErrorMessageBox>
  );
};

export default ErrorMessage;
