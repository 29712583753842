import { IMetrics } from "../../interfaces";

export const fireableMetrics = (metrics: IMetrics) => {
  const values: number[] = Object.values(metrics);
  let isFired: boolean = false;
  values.forEach(value => {
    if (value < 30) {
      isFired = true;
    }
  });
  return isFired;
};
