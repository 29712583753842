import React from "react";
import { IChoice } from "../../interfaces";
import { GenerateID } from "../../shared/helpers/testIdGenerator";
import { Button } from "semantic-ui-react";
import { StyledContainer } from "../../shared/components/StyledContainer";
import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";

const EventButtons = styled(StyledContainer)`
  @media ${device.tablet} {
    width: 400px;
    margin-left: calc(50% - 400px / 2) !important;
  }
`;

const ButtonFocus = styled(Button)`
  :focus {
    border-color: #006b92 !important;
    transition: border-color 0.2s;
  }
`;

interface EventProps {
  eventChoices: IChoice[];
  setEventOption: (selectedOption: IChoice) => void;
  isTutorial: boolean;
}

const EventOptions: React.FC<EventProps> = ({ eventChoices, setEventOption, isTutorial }) => {
  const [pressed, setPressed] = React.useState(false);
  const [clickedOption, setClickedOption] = React.useState("");

  return (
    <EventButtons data-testid="event-options-container">
      {eventChoices.map((choice: IChoice) => {
        const disabledIndicator = choice.choice !== "Fire the developer responsible" && isTutorial;
        return (
          <ButtonFocus
            key={GenerateID(choice.choice)}
            data-testid={GenerateID(choice.choice)}
            style={{
              margin: "1em 0 1em 0",
              width: "100%",
              height: "80px",
              color: pressed && clickedOption === choice.choice ? "#ffffff" : "#1b1c1d",
              background: pressed && clickedOption === choice.choice ? "#1b1c1d" : "#FFFFFF",
              border: "2px solid #E0E0E0",
              borderRadius: "10px",
            }}
            disabled={disabledIndicator}
            onClick={() => {
              setEventOption(choice);
              setPressed(true);
              setClickedOption(choice.choice);
            }}
          >
            {choice.choice}
          </ButtonFocus>
        );
      })}
    </EventButtons>
  );
};

export default EventOptions;
