import { IInitiativeSelection } from "../../initiatives/pages";
import { IEventsSelection } from "../../events/domain_model/eventList";

export function generatePlayData(
  name: string,
  initiativeState: IInitiativeSelection[],
  score: number,
  eventState: IEventsSelection[],
) {
  let gamePlayData = {
    name: name,
    initiatives: initiativeState,
    events: eventState,
    score: score,
  };
  return JSON.stringify(gamePlayData);
}
