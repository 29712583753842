import initialMetrics from "./company.json";
import { Company } from "../../app";
import { IMetrics } from "../../interfaces/metrics";

export const getStartingMetrics = (companyName?: Company, metric?: IMetrics): IMetrics => {
  let metrics = metric || { people: 70, customerSatisfaction: 70, productivity: 70, stability: 70 };

  if (companyName) {
    initialMetrics.forEach(item => {
      if (item.company === companyName.toString()) {
        metrics = item.metrics;
      }
    });
  }
  return metrics;
};
