import { Company } from "../../app";
import { IEventsSelection } from "../../events/domain_model/eventList";
import { IInitiativeSelection } from "../../initiatives/pages";
import { IMetrics } from "../../interfaces/metrics";
import { Image } from "semantic-ui-react";
import PageLayout from "../../shared/components/PageTemplate";
import React from "react";
import ReactGA from "react-ga4";
import { StyledContainer } from "../../shared/components/StyledContainer";
import angryBagel from "../../assets/images/firePlayer/angryBagel.svg";
import angryElephant from "../../assets/images/firePlayer/angryElephant.svg";
import angryGnome from "../../assets/images/firePlayer/angryGnome.svg";
import { device } from "../../shared/helpers/deviceConfig";
import { generatePlayData } from "../../shared/helpers/generatePlaythroughData";
import { getAverageScore } from "../../score/domain_model/getScore";
import styled from "styled-components";

const AngryImage = styled(Image)`
  display: flex;
  justify-content: center;
  margin: 35px;
`;

const TextContainer = styled.div`
  text-align: left;
  margin: 1rem;
  > p {
    @media ${device.laptop} {
      margin-left: 5px;
    }
    @media ${device.laptopL} {
      margin-left: 5px;
    }
    @media ${device.desktop} {
      margin-left: 227px;
    }
    @media ${device.desktopL} {
      margin-left: 227px;
    }
  }
`;
interface Props {
  setTutorial: (tutorial: boolean) => void;
  onDone: () => void;
  companyName: Company;
  playerName: string;
  initiativesState: IInitiativeSelection[];
  eventState: IEventsSelection[];
  metrics: IMetrics;
}

const startAgainClicked = (onDone: () => void) => {
  ReactGA.event({
    category: "End Game",
    action: "Player Fired: Play Again clicked",
  });
  onDone();
};

const FirePlayer = (props: Props) => {
  const [angryLogo, setAngryLogo] = React.useState("");
  const [timeLoaded] = React.useState(new Date().getTime());
  const [altText, setAltText] = React.useState("");

  React.useEffect(() => {
    return () => {
      ReactGA.event({
        category: `Player fired duration`,
        action: ((new Date().getTime() - timeLoaded) / 1000).toString(),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    generatePlayData(props.playerName, props.initiativesState, getAverageScore(props.metrics), props.eventState);
    switch (props.companyName) {
      case Company["Blockchain Bagels"]:
        setAngryLogo(angryBagel);
        setAltText("Angry bagel");
        break;
      case Company["Networked Gnomes"]:
        setAngryLogo(angryGnome);
        setAltText("Angry gnome");
        break;
      case Company["Elephant Enterprises"]:
        setAngryLogo(angryElephant);
        setAltText("Angry elephant");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      testId={"gameStart"}
      disabled={false}
      text={"Play again"}
      onClick={() => {
        props.setTutorial(false);
        startAgainClicked(props.onDone);
      }}
      includeFooter={true}
      includeNav={false}
      buttonIcon={true}
    >
      <StyledContainer>
        <p data-testid="playerFired-message" style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
          {" "}
          You're Fired!{" "}
        </p>
        <AngryImage>
          <Image src={angryLogo} alt={altText} style={{ display: "flex" }} />
        </AngryImage>
        <TextContainer>
          <p>
            <strong>Sorry {props.playerName}</strong>
          </p>
          <p>The board is displeased with your performance. Your metrics have dropped to an unacceptable level.</p>
          <p>
            <strong> They have asked you to step aside.</strong>
          </p>
          <p data-testid="wayfinderRecommendation">
            If you would like to improve your score, checkout <a href="https://wayfinder.ninja">WayFinder!</a>
          </p>
        </TextContainer>
      </StyledContainer>
    </PageLayout>
  );
};

export default FirePlayer;
