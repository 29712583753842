import { Image } from "semantic-ui-react";
import NextButton from "../components/NextButton";
import React from "react";
import ReactGA from "react-ga4";
import { SmallText } from "../components/Typography";
import { StyledContainer } from "../components/StyledContainer";
import logo from "../../assets/images/logo.svg";

interface Props {
  onDone: () => void;
}

const GameStartClicked = (onDone: () => void) => {
  ReactGA.event({
    category: "Homepage",
    action: "Start button hit",
  });
  onDone();
};

const Home: React.FC<Props> = ({ onDone }) => {
  ReactGA.send({ hitType: "pageview", page: "/" });

  return (
    <>
      <StyledContainer>
        <Image style={{ marginTop: "30%" }} src={logo} className="logo" alt="DevOpsDream logo. Cloud and cog" />
        <SmallText style={{ textAlign: "center" }}>by Mechanical Rock</SmallText>
        <div style={{ margin: "5em" }} />
        <SmallText style={{ textAlign: "center", marginBottom: "4em" }} data-testid="welcomeText">
          A game for modern tech leaders to improve <strong>software delivery</strong> and{" "}
          <strong>organisational performance</strong>, based on real industry insights.
        </SmallText>
        <NextButton testId="letsgo" onClick={() => GameStartClicked(onDone)} text="Let's Go" buttonIcon={false} />
      </StyledContainer>
    </>
  );
};

export default Home;
