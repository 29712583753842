import companyDetails from "./company.json";
import { Company } from "../../app";

export const getCompanyBudget = (companyName: Company): number => {
  let budget = 0;
  companyDetails.forEach(item => {
    if (item.company === companyName.toString()) {
      budget = item.budget;
    }
  });
  return budget;
};
