import React from "react";
import { LineChart, CartesianGrid, XAxis, Line, Tooltip, YAxis } from "recharts";
import { IYearReport } from "../../interfaces/calendarReport";
import { Company } from "../../app";
import { getStartingMetrics } from "../../company/domain_model/getStartingMetrics";
import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";
import { getMetricColour } from "../../score/domain_model/getProgressBarColour";

interface IYearlyTrend {
  report: IYearReport;
  company: Company;
}

const StyledChart = styled.div`
  @media ${device.mobileS} {
    margin-left: -25px;
  }

  @media ${device.iphone6} {
    margin-left: -30px;
  }

  @media ${device.tablet} {
    margin-left: -100px;
  }
  @media ${device.tabletPro} {
    margin-left: -88px;
  }
  @media ${device.fullHDTV720p} {
    margin-left: -71px;
  }
  @media ${device.laptopL} {
    margin-left: -82px;
  }
  @media ${device.fullHDTV1080p} {
    margin-left: -81px;
  }
`;

const getWidth = (width: number) => {
  if (width <= 400) {
    return width * 0.85;
  }
  if (width > 400 && width < 420) {
    return width * 0.7;
  }
  if (width >= 420 && width < 770) {
    return width * 0.55;
  }
  if (width >= 770 && width < 1280) {
    return width * 0.4;
  }
  if (width >= 1280 && width < 1440) {
    return width * 0.31;
  }
  if (width >= 1440 && width < 1920) {
    return width * 0.28;
  }
  if (width >= 1920 && width < 3840) {
    return width * 0.21;
  }
  if (width >= 3840) {
    return width * 0.11;
  }
};

const lineData = (report: IYearReport, company: Company) => {
  const startingMetrics = getStartingMetrics(company);
  let tableData: any[] = [
    {
      customerSatisfaction: startingMetrics.customerSatisfaction,
      people: startingMetrics.people,
      productivity: startingMetrics.productivity,
      stability: startingMetrics.stability,
      year: 0,
      quarter: "Q0",
    },
  ];
  Array.from(report.calendarYearsInfo).forEach(item => {
    Array.from(item.quarters).forEach(quarter => {
      tableData.push({
        customerSatisfaction:
          quarter.metrics.customerSatisfaction === 0 ? null : Math.round(quarter.metrics.customerSatisfaction),
        people: quarter.metrics.people === 0 ? null : Math.round(quarter.metrics.people),
        productivity: quarter.metrics.productivity === 0 ? null : Math.round(quarter.metrics.productivity),
        stability: quarter.metrics.stability === 0 ? null : Math.round(quarter.metrics.stability),
        year: item.count,
        quarter: item.quarters.indexOf(quarter) + 1 < 4 ? "Q" + (item.quarters.indexOf(quarter) + 1) : "Y" + item.count,
      });
    });
  });
  return tableData;
};

const YearlyTrend = ({ report, company }: IYearlyTrend) => {
  return (
    <StyledChart>
      <LineChart
        data-testid={"chart"}
        width={getWidth(window.innerWidth)}
        height={400}
        style={{
          fontSize: "11px",
          display: "inline-flex",
          overflowX: "hidden",
        }}
        data={lineData(report, company)}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="quarter" />
        <YAxis type="number" domain={[0, 100]} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="customerSatisfaction"
          stroke={getMetricColour("Satisfaction")}
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="people"
          stroke={getMetricColour("People")}
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="productivity"
          stroke={getMetricColour("Productivity")}
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="stability"
          stroke={getMetricColour("Stability")}
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Tooltip />
      </LineChart>
    </StyledChart>
  );
};
export default YearlyTrend;
