import * as React from "react";
import styled from "styled-components";
import Metrics from "../../../gameEngine/pages/Metrics";
import { IMetrics } from "../../../interfaces/metrics";
import PageLayout from "../../../shared/components/PageTemplate";
import { StyledContainer } from "../../../shared/components/StyledContainer";
import { device } from "../../../shared/helpers/deviceConfig";

const MetricsText = styled.div`
  > p {
    width: 303px;
    height: 150px;
  }
  > h2 {
    text-align: center;
    height: 105px;
  }

  @media ${device.tablet} {
    margin-bottom: 40px;
    > p {
      display: contents;
    }
  }
`;

interface IWhatHappened {
  onClose: () => void;
  onDone: () => void;
  isTutorial: boolean;
  metrics: IMetrics;
}

const WhatHappened: React.FC<IWhatHappened> = ({ onClose, isTutorial, onDone, metrics }) => {
  return (
    <PageLayout
      title="What happened?"
      icon="close"
      confirmExit={onClose}
      isTutorial={isTutorial}
      includeFooter={true}
      disabled={false}
      onClick={onDone}
      includeNav={true}
      buttonIcon={true}
    >
      <StyledContainer data-testid="poor-metrics">
        <MetricsText>
          <h2>
            <strong>You decided to fire the developer responsible.</strong>
          </h2>
          <p data-testid="what-happened-text">
            Unfortunately this has a <strong>negative effect</strong> on your team’s psychological safety, which then
            flows on to affect your company’s <strong>Productivity</strong> and your{" "}
            <strong>People’s performance.</strong>
          </p>
        </MetricsText>
        <Metrics metrics={metrics} displayHelperText={false} />
      </StyledContainer>
    </PageLayout>
  );
};
export default WhatHappened;
