import { initiatives as listOfInitiatives } from "./";
import { IInitiativeSelection } from "../pages";
import { Initiative } from "../../interfaces";

/**
 *
 * @deprecated - this should be replaced by initiativeCostNew?
 * @param selectedInitiative
 */
export const calculateCostPerOption = (selectedInitiative: string): number => {
  let costPerOption = 0;
  listOfInitiatives.forEach(item => {
    if (item.initiative === selectedInitiative) {
      const diffGap = item.budgetCosts.max - item.budgetCosts.min;
      costPerOption = diffGap / (item.label.length - 1);
    }
  });
  return costPerOption;
};

/**
 * @deprecated - this should not be required, if migrated to initiativeCostNew?
 * @param selectedInitiative
 */
export const getMinCost = (selectedInitiative: string) => {
  let minCost = 0;
  listOfInitiatives.forEach(item => {
    if (item.initiative === selectedInitiative) {
      minCost = item.budgetCosts.min;
    }
  });
  return minCost;
};

/**
 * @deprecated - This is unnecessarily complicated implementation, requiring too much semantic coupling with other areas
 *
 * @param indexOfOption
 * @param minCost
 * @param costPerOption
 */
export const initiativeCost = (indexOfOption: number, minCost: number, costPerOption: number) => {
  const updatedBudget = minCost + indexOfOption * costPerOption;
  return updatedBudget;
};

export const findIndexOfOption = (
  selectedInitiatives: IInitiativeSelection[],
  selectedInitiative: IInitiativeSelection["initiativeName"],
) => {
  let indexOfOption = -1;
  selectedInitiatives.forEach(initiative => {
    if (initiative.initiativeName === selectedInitiative) {
      listOfInitiatives.forEach(item => {
        if (item.initiative === selectedInitiative) {
          indexOfOption = item.label.indexOf(initiative.option);
        }
      });
    }
  });
  return indexOfOption;
};

export interface InitiativeCostParams {
  initiative: Initiative;
  fundingLevel: string;
}

// TODO - this shall replace initiativeCost function
// "Two phase commit"
export const initiativeCostNew = (params: InitiativeCostParams) => {
  const { initiative } = params;
  const indexOfOption = fundingLevelToIdx(params);
  const updatedBudget = initiative.budgetCosts.min + indexOfOption * costPerOption(initiative);
  return updatedBudget;
};

function fundingLevelToIdx(params: InitiativeCostParams): number {
  const { initiative, fundingLevel } = params;

  const matchingFundingLevel = (l: string): boolean => l === fundingLevel;
  return initiative.label.findIndex(matchingFundingLevel);
}

function costPerOption(initiative: Initiative) {
  const diffGap = initiative.budgetCosts.max - initiative.budgetCosts.min;
  const costPerOption = diffGap / (initiative.label.length - 1);
  return costPerOption;
}
