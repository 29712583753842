import * as React from "react";
import styled from "styled-components";
import AngryBagel from "../../../assets/images/AngryBagel.svg";
import { Image } from "semantic-ui-react";
import PageLayout from "../../../shared/components/PageTemplate";
import { StyledContainer } from "../../../shared/components/StyledContainer";
import { device } from "../../../shared/helpers/deviceConfig";

const Angry = styled(Image)`
  display: inline !important;
  height: 116px;
  margin: 35px;
  margin-left: calc(100% - 400px / 2);

  @media ${device.tablet} {
    margin-left: calc(80% - 500px / 2);
  }
  @media ${device.fullHDTV720p} {
    margin-left: calc(66% - 500px / 2);
  }
  @media ${device.fullHDTV1080p} {
    margin-left: calc(60% - 500px / 2);
  }
  @media ${device.desktopL} {
    height: 150px;
    margin-left: calc(48% - 700px / 2);
  }
`;

const NowWhatStyledContainer = styled(StyledContainer)`
  @media ${device.tablet} {
    margin-left: calc(57% - 500px / 2);
  }
  @media ${device.fullHDTV1080p} {
    margin-left: calc(52% - 550px / 2);
  }
`;

const Text = styled.div`
  > p {
    display: block;
    width: 303px;
    text-align: left;
    padding-left: 8px;
    @media ${device.fullHDTV1080p} {
      width: 90%;
      padding-left: 54px;
    }
    @media ${device.desktopL} {
      width: 60%;
      padding-left: 20px;
    }
`;

interface INowWhat1 {
  onClose: () => void;
  onDone: () => void;
  isTutorial: boolean;
  name: string;
}

const NowWhat1: React.FC<INowWhat1> = ({ onClose, isTutorial, onDone, name }) => {
  return (
    <PageLayout
      title={"You’re Fired!"}
      confirmExit={onClose}
      isTutorial={isTutorial}
      includeNav={true}
      icon={"close"}
      disabled={false}
      onClick={onDone}
      includeFooter={true}
      buttonIcon={true}
    >
      <NowWhatStyledContainer>
        <Angry src={AngryBagel} alt="Angry bagel" />
        <Text>
          <p>
            <strong>Sorry {name}.</strong>
          </p>
          <p data-testid="what-now-text">
            Our company’s reputation has been irreparably damaged by your hamfisted management of the situation.
          </p>
          <p>Your metrics have dropped to an unacceptable level.</p>
          <p>
            <strong>The board has asked you to step aside immediately.</strong>
          </p>
          <p>(Thank goodness this was just a tutorial!)</p>
        </Text>
      </NowWhatStyledContainer>
    </PageLayout>
  );
};
export default NowWhat1;
