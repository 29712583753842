import * as React from "react";
import InitiativeSelection from "./InitiativeSelection";
import InitiativeOptions from "./InitiativeOptions";
import { calculateCostPerOption, getMinCost } from "../domain_model/updateBudget";
import { revalidateCost, updateInitiativeList, findPreviousOption } from "../domain_model/updateInitiativeList";
import { removeInitiative } from "../domain_model/updateInitiativeList";
import { Initiative } from "../../interfaces";

export interface IInitiativeSelection {
  initiativeName: string;
  option: string;
  fundedYear: number;
}

export interface IInitiativeProps {
  selectedInitiatives: IInitiativeSelection[];
  totalBudget: number;
  tutorial: boolean;
  currentYear: number;
  initiativeFundLimit: number;
  onExitTutorial: () => void;
  onComplete: (initiativeList: IInitiativeSelection[], totalBudget: number, budgetPercentage: number) => void;
  budgetPercentage: number;
  initiatives: Initiative[];
}

const Initiatives = ({
  selectedInitiatives,
  tutorial,
  onExitTutorial,
  totalBudget,
  onComplete,
  currentYear,
  budgetPercentage,
  initiativeFundLimit,
  initiatives,
}: IInitiativeProps) => {
  const [page, setPage] = React.useState("selectInitiative");
  const [budgetToSpend, setBudgetToSpend] = React.useState(budgetPercentage);
  const [minCost, setMinCost] = React.useState(0);
  const [selectedInitiative, setSelectedInitiative] = React.useState("");
  const [costPerOption, setCostPerOption] = React.useState(0);
  const [previousCost, setPreviousCost] = React.useState(0);
  const [hasBudget, setHasBudget] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [previousSelectedOption, setPreviousSelectedOption] = React.useState("");
  const [fundedOption, setFundedOption] = React.useState("");
  const [draftInitiatives, setDraftInitiatives] = React.useState(selectedInitiatives);

  const close = () => {
    onExitTutorial();
  };

  const onChooseInitiative = (selectedInitiative: IInitiativeSelection["initiativeName"]) => {
    setPage("initiativeOptions");
    const updatedList = updateInitiativeList(draftInitiatives, selectedInitiative);
    setDraftInitiatives(updatedList);
  };
  const recalculateCosts = (playerInitiative: IInitiativeSelection["initiativeName"]) => {
    const optionCost = calculateCostPerOption(playerInitiative);
    const initiativeMinCost = getMinCost(playerInitiative);
    const lastInitiativeCost = revalidateCost({
      selectedInitiatives: draftInitiatives,
      playerInitiative,
      initiativeMinCost,
      optionCost,
    });
    const hasBudgetForCheapestOption = budgetToSpend + lastInitiativeCost - initiativeMinCost;
    if (hasBudgetForCheapestOption < 0) {
      setHasBudget(false);
      setErrorMessage("Sorry! You don't have enough budget");
    } else {
      setHasBudget(true);
      setPreviousCost(lastInitiativeCost);
      onChooseInitiative(playerInitiative);
    }
    setPreviousCost(lastInitiativeCost);
    setCostPerOption(optionCost);
    setMinCost(initiativeMinCost);
  };

  const CalculateBudget = React.useCallback((totalBudget: number, budgetToSpend: number) => {
    const getPercentOff = totalBudget - totalBudget * (1 - budgetToSpend);
    return getPercentOff;
  }, []);

  switch (page) {
    case "selectInitiative":
      return (
        <InitiativeSelection
          totalBudget={totalBudget}
          availableCredit={budgetToSpend}
          listInitiatives={initiatives}
          selectedInitiatives={draftInitiatives}
          setPlayerInitiative={setSelectedInitiative}
          setPage={setPage}
          hasBudget={hasBudget}
          updateBudget={setBudgetToSpend}
          onInitiativeClick={(playerInitiative: IInitiativeSelection["initiativeName"]) => {
            recalculateCosts(playerInitiative);
            const oldOption = findPreviousOption(draftInitiatives, playerInitiative);
            setPreviousSelectedOption(oldOption);
            setFundedOption(oldOption);
          }}
          onDeleteInitiativeClick={(playerInitiative: IInitiativeSelection["initiativeName"]) => {
            removeInitiative({
              selectedInitiatives: selectedInitiatives,
              selectedInitiative: playerInitiative,
            });
            recalculateCosts(playerInitiative);
          }}
          onClose={close}
          onDone={() => {
            onComplete(draftInitiatives, CalculateBudget(totalBudget, budgetToSpend), budgetToSpend);
          }}
          isTutorial={tutorial}
          errorMessage={errorMessage}
          currentYear={currentYear}
          initiativeFundLimit={initiativeFundLimit}
        />
      );
    case "initiativeOptions":
      return (
        <InitiativeOptions
          totalBudget={totalBudget}
          selectedInitiative={selectedInitiative}
          selectedInitiatives={draftInitiatives}
          setSelectedInitiatives={setDraftInitiatives}
          optionCost={costPerOption}
          initiativeMinCost={minCost}
          budgetToSpend={budgetToSpend}
          previousCost={previousCost}
          updateBudget={setBudgetToSpend}
          setPage={setPage}
          onClose={close}
          isTutorial={tutorial}
          currentYear={currentYear}
          previousSelectedOption={previousSelectedOption}
          fundedOption={fundedOption}
          setPreviousSelectedOption={setPreviousSelectedOption}
          removeInitiative={() => {
            removeInitiative({
              selectedInitiatives: draftInitiatives,
              selectedInitiative: selectedInitiative,
            });
          }}
        />
      );
    default:
      return <h1>Oops, you're not meant to be here.</h1>;
  }
};

export default Initiatives;
