import * as React from "react";

import CompanyChoices from "../components/CompanyChoices";

import { GameConfig, Company } from "../../app";
import ReactGA from "react-ga4";
import PageLayout from "../../shared/components/PageTemplate";

import { StyledContainer } from "../../shared/components/StyledContainer";
import styled from "styled-components";
import { device } from "../../shared/helpers/deviceConfig";

interface Props {
  setCompanyName: (companyName: GameConfig["companyName"]) => void;
  onDone: () => void;
  onClose: () => void;
  isTutorial: boolean;
  companyName: Company;
}

const CompanyStyledContainer = styled(StyledContainer)`
  @media ${device.mobileS} {
    max-width: 320px;
    margin-left: calc(50% - 320px / 2);
  }
  @media ${device.galaxyS} {
    max-width: 360px;
    margin-left: calc(50% - 360px / 2);
  }
  @media ${device.mobileM} {
    max-width: 380px;
    margin-left: calc(50% - 380px / 2);
  }
  @media ${device.mobileL} {
    max-width: 380px;
    margin-left: calc(50% - 380px / 2);
  }
  @media ${device.iphone6} {
    max-width: 420px;
    margin-left: calc(50% - 420px / 2);
  }
  @media ${device.tablet} {
    max-width: 770px;
    margin-left: calc(50% - 770px / 2);
  }

  @media ${device.tabletPro} {
    max-width: 840px;
    margin-left: calc(50% - 840px / 2);
  }
  @media ${device.laptop} {
    max-width: 1025px;
    margin-left: calc(50% - 1025px / 2);
  }
  @media ${device.fullHDTV720p} {
    max-width: 1280px;
    margin-left: calc(50% - 1280px / 2);
  }
`;

export const disabledCheck = (isTutorial: boolean, companyName: Company) => {
  return companyName !== Company["Blockchain Bagels"] && isTutorial;
};

const CompanySelection: React.FC<Props> = ({ setCompanyName, onDone, onClose, isTutorial, companyName }) => {
  const [timeLoaded] = React.useState(new Date().getTime());
  React.useEffect(() => {
    return () => {
      ReactGA.event({
        category: `Company selection duration`,
        action: ((new Date().getTime() - timeLoaded) / 1000).toString(),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      title="Choose a company"
      icon="close"
      confirmExit={onClose}
      isTutorial={isTutorial}
      disabled={disabledCheck(isTutorial, companyName)}
      onClick={onDone}
      includeFooter={true}
      includeNav={true}
      buttonIcon={true}
    >
      <CompanyStyledContainer>
        <h3 style={{ marginTop: "3rem", textAlign: "center", marginBottom: "3rem" }} data-testid="CIO-choice-header">
          Which CIO job will you accept?
        </h3>
        <CompanyChoices setCompanyName={setCompanyName} isTutorial={isTutorial} />
        <br />
      </CompanyStyledContainer>
    </PageLayout>
  );
};

export default CompanySelection;
