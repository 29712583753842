import * as React from "react";
import styled from "styled-components";
import { device } from "../helpers/deviceConfig";
import { Icon, Confirm } from "semantic-ui-react";

const NavContainer = styled.div`
  flex-direction: column;
  border-bottom: 1px solid #dfdfdf;
  > p {
    font-family: Mukta;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #333333;
  }

  @media ${device.mobileS} {
    display: block;
  }

  @media ${device.mobileM} {
    display: block;
  }
  @media ${device.mobileL} {
    display: block;
  }
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }
`;
const CloseIcon = styled(Icon)`
  display: block;
  margin-top: 2rem;
`;

const TitleIconContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  grid-template-rows: 2rem;
  font-weight: bold;
  font-size: 18px;
  align-items: baseline;
  padding: 1rem 0.5rem;
  > p {
    grid-column: 2;
    justify-self: center;
    font-family: Arial;
  }
`;
export interface IWideNavigation {
  title?: string;
  iconName?: string;
  confirmExit?: () => void;
  isTutorial?: boolean;
  dataTestId?: string;
}
const WideNavigation: React.FC<IWideNavigation> = ({
  title,
  children,
  iconName,
  isTutorial,
  confirmExit,
  dataTestId,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const showConfirmation = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <NavContainer className="navBar-title" data-testid="page-navbar">
      <TitleIconContainer>
        <p data-testid={dataTestId}>{title}</p>
        {isTutorial && (
          <CloseIcon
            role="button"
            data-testid="exit-tutorial-btn"
            name={iconName}
            size="small"
            onClick={showConfirmation}
            // style={{ marginLeft: "calc(100% - 40px)" , marginTop:`${window.innerWidth < 3}` }}
          />
        )}
      </TitleIconContainer>
      {children}
      <Confirm
        data-testid="confirmMessage"
        open={isOpen}
        header="Confirm exit tutorial"
        content="Are you sure you want to exit the tutorial?"
        cancelButton="Cancel"
        confirmButton="Exit Tutorial"
        onCancel={handleCancel}
        onConfirm={confirmExit}
      />
    </NavContainer>
  );
};
export default WideNavigation;
